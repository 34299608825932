import AuthorizeUser from "../components/authorizeUser";
import CompanyPage from "../pages/CompanyPage";
import OfferPage from "../pages/OfferPage";
import OrderPage from "../pages/OrderPage";
import OrderRequestPage from "../pages/OrderRequestPage";
import OrdersPage from '../pages/OrdersPage';
import SettingsPage from "../pages/SettingsPage";
import UserPage from "../pages/UserPage";

export const routes = [
  {
    path: '/authorize/:accessToken',
    content: AuthorizeUser
  },
  {
    path: '/company',
    content: CompanyPage
  },
  {
    path: '/user',
    content: UserPage
  },
  {
    path: '/orders',
    content: OrdersPage
  },
  {
    path: '/request/:orderId',
    content: OrderRequestPage
  },
  {
    path: '/order/:orderId',
    content: OrderPage
  },
  {
    path: '/offer/:orderId',
    content: OfferPage
  },
  {
    path: '/settings',
    content: SettingsPage
  },
  {
    path: '/*',
    content: CompanyPage
  }
]