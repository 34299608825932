import React from 'react'
import clsx from 'clsx'

import makeStyles from '@mui/styles/makeStyles'
import {
  Box,
  Typography,
  TextField
} from '@mui/material'

import { componentStyles } from '../../styles'

export default function ProfileText(props) {
  const classes = useStyles()
  const _classes = componentStyles()

  return (
    <Box className={clsx(_classes.container, classes.container)}>

      <Typography variant='h5'>
        Profiltekst*
      </Typography>

      <Box mt={2}>
        <TextField
          name="description"
          placeholder="Skriv om jeres virksomhed, de services i tilbyder kunder og hvorfor kunder skal vælge jer til deres næste projekt."
          variant="outlined"
          value={props.value || ''}
          error={props.error && !props.value}
          fullWidth
          onChange={props.handleChange}
          multiline
          rows={14}
        />
      </Box>
    </Box>
  )
}

const useStyles = makeStyles({
  container: {
    width: '100%',
    height: '440px',
  }
});