import React, { useState, useEffect } from 'react'
import clsx from 'clsx'
import YouTube from 'react-youtube'
import queryString from 'query-string'

import makeStyles from '@mui/styles/makeStyles'
import {
  Box,
  Tabs,
  Tab
} from '@mui/material'

import { API_ENDPOINT } from '../../../api'
import { componentStyles } from '../../styles'
import { ImageDialog } from '../../dialogs/imageDialog'
import * as companyApi from '../../../api/company'

export default function Media(props) {
  const classes = useStyles()
  const _classes = componentStyles()
  const [value, setValue] = useState(0);
  const [gallery, setGallery] = useState([])
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    async function getGallery() {
      const data = await companyApi.getGallery()
      if (data && data.length > 0) {
        setGallery(data)
      } else {
        setValue(1)
      }
      setLoading(false)
    }

    getGallery()
  }, [])

  if (loading) return <React.Fragment />

  if (gallery.length < 1 && !props.video) return <React.Fragment />

  return (
    <Box className={clsx(_classes.container, classes.container)}>

      <Tabs value={value} onChange={(e, value) => setValue(value)} indicatorColor="primary">
        {gallery.length > 0 && <Tab label="Galleri" value={0} />}
        {props.video && <Tab label="Video" value={1} />}
      </Tabs>

      <Box mt={2}>
        <Box role="tabpanel" hidden={value !== 0} id='galleriTab'>
          {value === 0 && (
            <Box className={classes.galleryContainer}>
              {gallery.map(image =>
                <React.Fragment key={image.image}>
                  <RenderImage image={image.image} />
                </React.Fragment>
              )}
            </Box>
          )}
        </Box>

        <Box role="tabpanel" hidden={value !== 1} id='videoTab'>
          {value === 1 && (
            <YouTube videoId={queryString.parseUrl(props.video).query.v} opts={{ width: '738px', height: '415px' }} />
          )}
        </Box>

      </Box>
    </Box>
  )
}

function RenderImage(props) {
  const [show, setShow] = useState(false)

  return <Box mr={3} key={props.image}>
    <img src={`${API_ENDPOINT}/file/image/${props.image}`} height={'100%'} alt='' onClick={() => setShow(true)} />
    <ImageDialog image={props.image} open={show} close={() => setShow(false)} />
  </Box>
}

const useStyles = makeStyles({
  container: {
  },
  galleryContainer: {
    display: 'flex',
    height: '220px',
    paddingBottom: '10px',
    overflowX: 'scroll'
  }
});