import HelpIcon from '@mui/icons-material/Help'
import {
  Avatar, Box, Button, IconButton, Link, Popper, Typography
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import React, { useContext } from 'react'
import { LOGIN_URL } from '../api'
import { UserContext } from '../contexts/userContext'

export default function Me() {
  const classes = useStyles()
  const user = useContext(UserContext)
  const [anchorEl, setAnchorEl] = React.useState(null);

  const logoff = () => {
    localStorage.removeItem('jwtToken')
    window.location.replace(`${LOGIN_URL}/logoff`)
  }

  return (
    <Box className={classes.container}>
      <Avatar className={classes.avatar}>{user.info.name?.slice(0, 1)}</Avatar>
      <Typography style={{ fontSize: '15px', fontWeight: '700', marginRight: '10px' }}>
        {user.info.name || user.info.email}
      </Typography>

      <IconButton onClick={(event) => setAnchorEl(anchorEl ? null : event.currentTarget)}>
        <HelpIcon style={{ fontSize: '25px', color: 'white' }} />
      </IconButton>

      <Box ml={2} onClick={logoff} style={{ cursor: 'pointer' }}>
        <Typography variant='body'>
          Log af
        </Typography>
      </Box>

      <Popper open={Boolean(anchorEl)} anchorEl={anchorEl} className={classes.support} placement={'bottom-end'}>
        <Typography variant='h5'>
          Support
        </Typography>

        <Typography variant='subtitle2'>
          Har du brug for hjælp? Kontakt support ved at trykke på knappen herunder
        </Typography>

        <Link href={`${LOGIN_URL}/contact`} target='_blank'>
          <Button variant='contained'>
            Support
          </Button>
        </Link>
      </Popper>
    </Box >
  )
}

const useStyles = makeStyles({
  container: {
    height: '40px',
    minWidth: '300px',
    padding: '10px 15px',
    display: 'flex',
    position: 'fixed',
    top: '0',
    right: '45px',
    background: 'linear-gradient(0deg, #061B37 0%, #102D53 100%)',
    borderRadius: '0px 0px 5px 5px',
    color: 'white',
    alignItems: 'center',
    zIndex: '5'
  },
  avatar: {
    fontSize: '14px',
    fontWeight: '700',
    backgroundColor: '#007EA6',
    marginRight: '10px',
    width: '25px',
    height: '25px',
    textTransform: 'uppercase'
  },
  support: {
    width: '340px',
    height: '220px',
    background: '#FFFFFF',
    borderRadius: '5px',
    zIndex: '5',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    padding: '0 24px',
    textAlign: 'center',
    boxShadow: '0px 5px 15px rgba(0, 0, 0, 0.20)'
  }
});