import React from 'react'
import ErrorIcon from '@mui/icons-material/Error'
import {
  Dialog,
  Stack,
  Button,
  Typography
} from '@mui/material'

export default function AcceptCurrentOfferDialog(props) {
  return (
    <Dialog open={props.open}>
      <Stack sx={style} gap={3}>
        <Typography variant='h3'>
          Kunden har lavet ændringer!
        </Typography>

        <Stack sx={changes}>
          <Typography>
            Kunden har lavet ændringer i ordren.{<br></br>}Se ændringerne markeret med ikonet herunder.
          </Typography>
          <ErrorIcon color='red' fontSize="large" />
        </Stack>

        <Stack sx={notice}>
          <Typography variant='h4'>
            Bemærk!
          </Typography>
          <Typography>
            Hvis kundens ændringer påvirker ordrens pris eller leveringstid skal et nyt tilbud vedhæftes og informationen opdateres!
          </Typography>
        </Stack>
        <Button variant='contained' onClick={props.onNext}>
          Forstået
        </Button>
      </Stack>
    </Dialog >
  )
}

const style = {
  background: 'radial-gradient(#102D53 0%, #061B37 100%)',
  boxShadow: '0px 5px 15px rgba(0, 0, 0, 0.25)',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '20px',
  color: 'white',
  textAlign: 'center'
}

const changes = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center'
}

const notice = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '20px, 20px, 20px, 20px',
}