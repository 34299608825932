import makeStyles from '@mui/styles/makeStyles';

export const dialogStyles = makeStyles({
  dialog: {
    width: '500px',
    background: 'radial-gradient(#102D53 0%, #061B37 100%)',
    boxShadow: '0px 5px 15px rgba(0, 0, 0, 0.25)',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
    color: 'white'
  },
  input: {
    background: 'white'
  }
})