import React from 'react'

import {
  Backdrop
} from '@mui/material'

import { API_ENDPOINT } from '../../api'
import makeStyles from '@mui/styles/makeStyles';

export function ImageDialog(props) {
  const classes = useStyles()

  return (
    <Backdrop open={props.open} onClick={() => props.close()} className={classes.Backdrop}>
      <img src={`${API_ENDPOINT}/file/image/${props.image}`} alt='' style={{ maxWidth: '100%', maxHeight: '95%' }} />
    </Backdrop>
  )
}

const useStyles = makeStyles({
  Backdrop: {
    zIndex: 5,
    width: '100%',
    height: '100%'
  },
})