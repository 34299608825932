import React from 'react'
import makeStyles from '@mui/styles/makeStyles'
import { Box, Typography, Table, TableBody, TableCell, TableHead, TableRow, Button, Input, Tooltip, Stack } from '@mui/material'
import ErrorIcon from '@mui/icons-material/Error'
import * as fileApi from '../../api/file'
import NumberFormat from 'react-number-format'
import { handlePriceString } from '../../helpers/utilities'

const cells = [
  { id: 'name', label: 'Navn', align: 'left', sortable: true },
  { id: 'amount', label: 'Type', align: 'right', sortable: true },
  { id: 'value', label: 'Filer', align: 'right', sortable: true },
  { id: 'quantity', label: 'Antal', align: 'right', sortable: true },
]

const offerCells = [
  { id: 'price', label: 'Stk. pris', align: 'right', sortable: true },
  { id: 'total', label: 'Total', align: 'right', sortable: true }
]

export function FileTable(props) {
  const classes = useStyles()

  return (
    <React.Fragment>
      <Table className={classes.fileTable} size='small'>

        <TableHead>
          <TableRow className={classes.tableHeader}>

            {React.Children.toArray(
              cells.map(cell => (
                <TableCell align={"center"} style={{ border: '2px solid white' }}>
                  <Box color='white' display='flex' justifyContent='center' alignItems='center'>
                    <Typography variant='h6'>{cell.label}</Typography>
                  </Box>
                </TableCell>
              )))}

            {props.offer && React.Children.toArray(
              offerCells.map(cell => (
                <TableCell align={"center"} style={{ border: '2px solid white' }}>
                  <Box color='white' display='flex' justifyContent='center' alignItems='center'>
                    <Typography variant='h6'>{cell.label}</Typography>
                  </Box>
                </TableCell>
              )))}

          </TableRow>
        </TableHead>

        <TableBody>

          {React.Children.toArray(
            props.orderItems.map(orderItem => (
              <TableRow>
                <TableCell>
                  <Typography variant='h6' noWrap>
                    {orderItem.name}
                  </Typography>
                </TableCell>

                <TableCell>
                  <Typography variant='h6'>
                    {orderItem.type === 'single' ? 'Emne' : orderItem.type === 'construction' ? 'Konstruktion' : ''}
                  </Typography>
                </TableCell>

                <TableCell>
                  <Stack direction={'row'} columnGap={2} rowGap={0.5} justifyContent={'center'} flexWrap={'wrap'}>
                    {React.Children.toArray(
                      orderItem.files.map(item => <Stack direction={'row'} alignItems={'center'} gap={0.5}>
                        <Button
                          style={{ textTransform: 'none', color: '#000', padding: 0 }}
                          onClick={() => fileApi.getOrderRequestFile(props.orderId, item.fileId, item.name)}
                        >
                          {item.name}
                        </Button>

                        {props.orderEdits?.find(edit => edit.type === 'file' && edit.identifier === item.fileId) &&
                          <Tooltip title="Kunden har foretaget en ændring" arrow>
                            <ErrorIcon color='red' />
                          </Tooltip>
                        }
                      </Stack>)
                    )}
                  </Stack>
                </TableCell>

                <TableCell>
                  <Box display='flex' justifyContent={'center'} alignItems='center'>
                    <Typography variant={props.offer ? '' : 'h6'}>
                      {orderItem.quantity}
                    </Typography>

                    {props.orderEdits?.find(edit => edit.type === 'orderItemQuantity' && edit.identifier === orderItem.id) &&
                      <Tooltip title="Kunden har foretaget en ændring" arrow>
                        <ErrorIcon color='red' fontSize='small' />
                      </Tooltip>
                    }
                  </Box>
                </TableCell>

                {props.offer &&
                  <TableCell>
                    {orderItem.quantity <= 0 && <Typography>0</Typography>}
                    {orderItem.quantity > 0 &&
                      <Input
                        value={props.orderItemPrices[orderItem.id] || ''}
                        type="tel"
                        inputProps={{
                          className: classes.amountInput,
                          style: { borderColor: props.error && !props.orderItemPrices[orderItem.id] ? 'red' : '' }
                        }}
                        className={classes.amountContainer}
                        onChange={(event) => props.handlePriceUpdate(event, orderItem.id)}
                        onBlur={(() => props.updateDraft())}
                      />
                    }
                  </TableCell>
                }

                {props.offer &&
                  <TableCell>
                    <Typography>
                      <NumberFormat
                        value={orderItem.quantity * handlePriceString(props.orderItemPrices[orderItem.id])}
                        displayType={'text'}
                        thousandSeparator={'.'}
                        decimalSeparator={','}
                        decimalScale={2}
                        suffix={' kr.'}
                        style={{ marginLeft: '5px' }}
                      />
                    </Typography>
                  </TableCell>
                }

              </TableRow>
            )))}
        </TableBody>

      </Table>
    </React.Fragment>
  )
}

const useStyles = makeStyles({
  fileTable: {
    width: '100%',
    borderCollapse: 'collapse',
    background: 'white'
  },
  tableHeader: {
    backgroundColor: '#061B37',
  },
  amountInput: {
    width: '65px',
    height: '40px',
    border: '2px solid #DD8500',
    textAlign: 'center',
    borderRadius: '5px',
    padding: 0
  },
  amountContainer: {
    border: 'none !important',
    '&::before': {
      border: 'none !important'
    },
    '&::after': {
      border: 'none !important'
    }
  }
})