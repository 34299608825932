import React from 'react';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom"
import ReactGA from 'react-ga'
import daLocale from 'date-fns/locale/da'

import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import {
  CssBaseline,
  Box
} from '@mui/material'

import Header from './components/header'
import Me from './components/me'
import { mainTheme } from './helpers/themes'
import { routes } from './helpers/routes'
import { UserProvider } from './contexts/userContext'
import { CompanyProvider } from './contexts/companyContext'
import { SnackbarProvider } from 'notistack'

if (process.env.NODE_ENV === 'production') {
  ReactGA.initialize("")
} else {
  ReactGA.initialize("")
}

function App() {
  return (
    <Router>
      <Box className='background'>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={mainTheme}>
            <SnackbarProvider anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} preventDuplicate>
              <CssBaseline />
              <LocalizationProvider dateAdapter={AdapterDateFns} locale={daLocale}>
                <Box className='main'>
                  <UserProvider>
                    <CompanyProvider>
                      <Header />
                      <Me />
                      <Route render={({ location }) => (
                        <Switch location={location} key={location.pathname}>
                          {routes.map((route, index) => {
                            return <Route key={index} path={route.path} component={route.content} />
                          })}
                        </Switch>
                      )} />
                    </CompanyProvider>
                  </UserProvider>
                </Box>
              </LocalizationProvider>
            </SnackbarProvider>
          </ThemeProvider>
        </StyledEngineProvider>
      </Box>
    </Router>
  );
}

export default App
