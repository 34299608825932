import axios from 'axios'
import { buildApiHeaders, SUPPLIER_ENDPOINT } from '.'

const API_ROUTE = `${SUPPLIER_ENDPOINT}/subscription`

export async function checkout(priceId, destination) {
  const url = `${API_ROUTE}/checkout`

  const response = await axios.post(url, {
    priceId,
    destination
  }, { headers: buildApiHeaders() })
    .catch(err => err.response)

  if (response?.data) {
    window.location.href = response.data
  }

  return response ? response.data : null
}

export async function manage() {
  const url = `${API_ROUTE}/manage`

  const response = await axios.post(url, {
  }, { headers: buildApiHeaders() })
    .catch(err => err.response)

  if (response?.data) {
    window.location.href = response.data
  }

  return response ? response.data : null
}

export async function cancelDowngrade() {
  const url = `${API_ROUTE}/cancelDowngrade`

  await axios.post(url, {
  }, { headers: buildApiHeaders() })
    .catch(err => err.response)

  window.location.reload()
}