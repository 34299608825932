import {
  Box
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import React, { useEffect, useState } from 'react'
import ChooseMembership from '../../components/company/settings/chooseMembership'
import Employees from '../../components/company/settings/employees'
import Membership from '../../components/company/settings/membership'
import Notifications from '../../components/company/settings/notifications'
import { useQuery } from '../../helpers/hooks'

export default function Settings() {
  const classes = useStyles()
  const [memberships, showMemberships] = useState(false)
  const query = useQuery()

  useEffect(() => {
    if (query.get('memberships') === 'true') {
      showMemberships(true)
    }

    // eslint-disable-next-line
  }, [])

  return (
    <Box className={classes.container} mt={3}>

      {memberships && <ChooseMembership back={() => showMemberships(false)} />}

      {!memberships && <>
        <Box width='50%' height='100%' mr={3}>
          <Notifications />

          <Membership change={showMemberships} />

          {/* <Profile /> */}
        </Box>

        <Employees />
      </>}

    </Box>
  )
}

const useStyles = makeStyles({
  container: {
    width: '100%',
    display: 'flex',
  }
});