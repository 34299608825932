import React from 'react'

import makeStyles from '@mui/styles/makeStyles'
import {
  Box,
  Typography,
} from '@mui/material'

export default function ServiceGuide(props) {
  const classes = useStyles()

  return (
    <Box className={classes.container}>

      <Box mb={2}>
        <Typography variant='h5'>
          1. Vælg produktionsydelser:
        </Typography>
      </Box>

      <Box mb={3}>
        <Typography>
          Vælg de produktionsmetoder i tilbyder.
        </Typography>
      </Box>

      <Box mb={4}>
        <Typography style={{ fontStyle: 'italic' }} >
          Husk at tilføje ydelser der varetages igennem et samarbejde med en partnervirksomhed (Eks.: pulverlakering).
        </Typography>
      </Box>

      <Box mb={2}>
        <Typography variant='h5'>
          2. Vælg materialer:
        </Typography>
      </Box>

      <Box mb={4}>
        <Typography>
          Vælg de materialer i arbejder med.
        </Typography>
      </Box>

      {/* <Box mb={2}>
        <Typography variant='h5'>
          3. (Valgfri) 3D print:
        </Typography>
      </Box>

      <Box mb={4}>
        <Typography>
          Er 3D print en primær eller sekundær ydelse? Tilføj ydelser der relaterer til 3D print.
        </Typography>
      </Box> */}

    </Box>
  )
}

const useStyles = makeStyles({
  container: {
    width: '100%',
    marginBottom: '15px'
  },
});