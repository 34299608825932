export function buildApiHeaders(token = localStorage.getItem('jwtToken')) {
  return {
    Authorization: `Bearer ${token}`
  }
}

const hostname = window && window.location && window.location.hostname

let API_ENDPOINT = ''
let SUPPLIER_ENDPOINT = ''
let LOGIN_URL = ''

if (hostname.indexOf('localhost') > -1) {
  LOGIN_URL = 'http://localhost:3000'
  API_ENDPOINT = 'http://localhost:8080/api'
  SUPPLIER_ENDPOINT = 'http://localhost:8080/api/supplier'
} else if (hostname.indexOf('supplybase-staging') > -1) {
  LOGIN_URL = 'https://landing-page-staging-supplybase.vercel.app'
  API_ENDPOINT = 'https://backend-dot-supplybase-staging.ey.r.appspot.com/api'
  SUPPLIER_ENDPOINT = 'https://backend-dot-supplybase-staging.ey.r.appspot.com/api/supplier'
} else {
  LOGIN_URL = 'https://supplybase.dk'
  API_ENDPOINT = 'https://backend-dot-supplybase-320615.ey.r.appspot.com/api'
  SUPPLIER_ENDPOINT = 'https://backend-dot-supplybase-320615.ey.r.appspot.com/api/supplier'
}

export {
  API_ENDPOINT,
  LOGIN_URL,
  SUPPLIER_ENDPOINT
}
