import React from 'react'
import clsx from 'clsx'

import makeStyles from '@mui/styles/makeStyles'
import {
  Box,
  Typography,
  TextField
} from '@mui/material'

import { componentStyles } from '../../styles'

export default function Tagline(props) {
  const classes = useStyles()
  const _classes = componentStyles()

  return (
    <Box className={clsx(_classes.container, classes.container)}>

      <Typography variant='h5'>
        Tagline
      </Typography>

      <Box mt={2}>
        <TextField
          name="slogan"
          label="Tagline"
          variant="outlined"
          placeholder='(Max 100 tegn) '
          value={props.value || ''}
          inputProps={{ maxLength: 100 }}
          fullWidth
          onChange={props.handleChange}
        />
      </Box>

    </Box>
  )
}

const useStyles = makeStyles({
  container: {
    width: '100%',
    marginBottom: '15px'
  },
});