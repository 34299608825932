import {
  Box, Typography
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import React, { useContext, useEffect, useState } from 'react'
import Profile from '../containers/company/profile'
import Wizard from '../containers/editCompany/wizard'
import { CompanyContext } from '../contexts/companyContext'
import { pageStyles } from './styles'

function CompanyPage() {
  const classes = useStyles()
  const _classes = pageStyles()
  const company = useContext(CompanyContext)

  const [edit, setEdit] = useState(false)

  useEffect(() => {
    if (!company.loading && company.noCompany) {
      setEdit(true)
    }

    // eslint-disable-next-line
  }, [company.loading])

  if (company.loading) {
    return <Box />
  }

  return (
    <Box className={classes.container}>
      <Box className={_classes.pageTitle}>
        {company.noCompany && <Typography variant='h3'>Opret virksomhed</Typography>}
        {!company.noCompany && <Typography variant='h3'>Min virksomhed</Typography>}
      </Box>

      <Box p={3} pt={0} width='100%' style={{ overflowY: 'scroll' }}>

        <React.Fragment>
          {edit && <Wizard setEdit={setEdit} />}

          {!edit && <Profile setEdit={setEdit} public={company.info.public} />}
        </React.Fragment>

      </Box>
    </Box>
  )
}

export default CompanyPage

const useStyles = makeStyles({
  container: {
    width: '100%',
    height: '100%',
    display: 'flex',
    paddingTop: '56px',
    flexDirection: 'column',
    alignItems: 'flex-start',
  }
});