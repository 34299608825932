import axios from 'axios'
import { SUPPLIER_ENDPOINT, buildApiHeaders } from '.'
import * as fileApi from './file'

const API_ROUTE = `${SUPPLIER_ENDPOINT}/offer`

export async function createOffer(orderReqId, itemPrices, extraItems, deliveryDate, file, total, reference, tradeTerms, offerNumber) {
  const url = `${API_ROUTE}/create`

  let fileId = null;
  if (file) fileId = await fileApi.addFile(file);

  return axios.post(url, {
    orderReqId,
    itemPrices,
    extraItems,
    deliveryDate,
    fileId,
    total,
    reference,
    tradeTerms,
    offerNumber
  }, {
    headers: buildApiHeaders()
  })
}

export async function editOffer(offerId, offerData, file) {
  const url = `${API_ROUTE}/edit`
  let fileId = null;
  if (file) fileId = await fileApi.addFile(file);
  return axios.put(url, {
    offerId: offerId,
    itemPrices: offerData.orderItemPrices,
    extraItems: offerData.extraItems,
    deliveryDate: offerData.deliveryDate,
    reference: offerData.reference,
    tradeTerms: offerData.tradeTerms,
    fileId: fileId,
    offerNumber: offerData.offerNumber
  }, {
    headers: buildApiHeaders()
  })
}

export async function getOfferFile(offerId) {
  const url = `${API_ROUTE}/offerFile`

  return await axios.get(url, {
    params: {
      offerId
    },
    headers: buildApiHeaders(),
    responseType: 'blob'
  })
}

export async function acceptCurrent(offerId) {
  const url = `${API_ROUTE}/accept`

  return await axios.put(url,
    {
      offerId: offerId
    },
    {
      headers: buildApiHeaders()
    });
}

export async function replaceOfferFile(offerId, file) {
  const url = `${API_ROUTE}/offerFile`;

  const formData = new FormData()
  formData.append('offerId', offerId)
  formData.append('file', file)

  const config = Object.assign(buildApiHeaders(), { 'content-type': 'multipart/form-data' })
  return await axios.put(url, formData, { headers: config });
}

export async function getOffers() {
  const url = `${API_ROUTE}/list`

  const response = await axios.get(url, {
    headers: buildApiHeaders()
  }).catch(err => err.response)

  return response ? response.data : null
}

export async function getDraft(offerId) {
  const url = `${API_ROUTE}/draft`

  const response = await axios.get(url, {
    params: {
      offerId
    },
    headers: buildApiHeaders()
  }).catch(err => err.response)

  return response ? response.data : null
}

export async function updateDraft(offerId, orderItemPrices, extraItems) {
  const url = `${API_ROUTE}/draft`

  const response = await axios.post(url, {
    offerId,
    orderItemPrices,
    extraItems
  }, {
    headers: buildApiHeaders()
  }).catch(err => err.response)

  return response ? response.data : null
}