import axios from 'axios'
import { buildApiHeaders, SUPPLIER_ENDPOINT, API_ENDPOINT } from '.'

const API_ROUTE = `${SUPPLIER_ENDPOINT}/order`

export async function getRequests() {
  const url = `${API_ROUTE}/requests`
  const response = await axios.get(url, { headers: buildApiHeaders() })
    .catch(err => err.response)

  return response ? response.data : null
}

export async function acceptNda(orderId, name, accept) {
  const url = `${API_ROUTE}/nda/accept`
  return axios.post(url, {
    orderId,
    name,
    accept
  }, { headers: buildApiHeaders() })
}

export async function getOrders(status) {
  const url = `${API_ROUTE}/list`
  const response = await axios.get(url, {
    params: {
      status
    },
    headers: buildApiHeaders()
  }).catch(err => err.response)

  return response ? response.data : null
}

export async function getRequest(orderId) {
  const url = `${API_ROUTE}/request`
  const response = await axios.get(url, {
    params: {
      orderId
    },
    headers: buildApiHeaders()
  }).catch(err => err.response)

  return response ? response.data : null
}

export async function hasAccessToRequest(orderId) {
  const url = `${API_ROUTE}/request/access`
  return axios.get(url, {
    params: {
      orderId
    },
    headers: buildApiHeaders()
  })
}

export async function getNda(orderId) {
  const url = `${API_ROUTE}/nda`
  return axios.get(url, {
    params: {
      orderId
    }, headers: buildApiHeaders()
  })
}

export async function getStandardNda() {
  return axios.get(`${API_ENDPOINT}/nda`)
}

export async function openNda(orderId) {
  const url = `${API_ROUTE}/nda`
  axios.get(url, {
    params: {
      orderId
    },
    headers: buildApiHeaders()
  })
    .then(response => {
      if (response.data.fileId) {
        window.open(`${API_ENDPOINT}/file/image/${response.data.fileId}`, "_blank")
      } else {
        axios.get(`${API_ENDPOINT}/nda`)
          .then(response => {
            window.open(`${API_ENDPOINT}/file/image/${response.data.fileId}`, "_blank")
          })
      }
    })

}

export async function rejectRequest(orderId, comment) {
  const url = `${API_ROUTE}/reject`
  return axios.post(url, {
    orderId,
    comment
  }, { headers: buildApiHeaders() })
}

export async function getOffer(orderId) {
  const url = `${API_ROUTE}/`
  const response = await axios.get(url, {
    params: {
      orderId
    },
    headers: buildApiHeaders()
  }).catch(err => err.response)

  return response ? response.data : null
}

export async function changeDeliveryDate(offerId, newDate) {
  const url = `${API_ROUTE}/deliveryDate`
  const response = await axios.post(url, {
    offerId,
    newDate
  }, { headers: buildApiHeaders() })
    .catch(err => err.response)

  return response ? response.data : null
}

export async function getComments(requestId) {
  const url = `${API_ROUTE}/comments`
  return axios.get(url, {
    params: {
      orderRequestId: requestId
    },
    headers: buildApiHeaders()
  })
}

export async function sendComment(requestId, comment) {
  const url = `${API_ROUTE}/comment`
  return axios.post(url, { orderRequestId: requestId, comment: comment }, { headers: buildApiHeaders() })
}