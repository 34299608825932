import React from 'react'
import {
  Dialog,
  Stack,
  Button,
  Typography
} from '@mui/material'

export default function AcceptCurrentOfferDialog(props) {
  return (
    <Dialog open={props.open}>
      <Stack sx={style} gap={3}>
        <Typography variant='h3'>
          Tilbuddet er uændret
        </Typography>
        <Typography>
          Du har godkendt det aktuelle tilbud udet at lave ændringer i ordren.
        </Typography>
        <Button variant='contained' onClick={props.onNext}>
          Afslut
        </Button>
      </Stack>
    </Dialog >
  )
}

const style = {
  background: 'radial-gradient(#102D53 0%, #061B37 100%)',
  boxShadow: '0px 5px 15px rgba(0, 0, 0, 0.25)',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '20px',
  color: 'white'
}