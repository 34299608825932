import React from 'react'
import { useParams } from "react-router-dom"

import makeStyles from '@mui/styles/makeStyles'
import {
  Box,
  Typography,
} from '@mui/material'

import { pageStyles } from './styles'
import Order from '../containers/orders/order'

export default function OfferPage() {
  const classes = useStyles()
  const _classes = pageStyles()

  let { orderId } = useParams();

  return (
    <Box className={classes.container}>
      <Box className={_classes.pageTitle}>
        <Typography variant='h3'>{`Tilbudsnummer: ${orderId}`}</Typography>
      </Box>

      <Box width='100%' p={3} style={{ overflowY: 'scroll' }}>

        <Box>
          <Order id={orderId} offer={true} />
        </Box>

      </Box>

    </Box>
  )
}

const useStyles = makeStyles({
  container: {
    width: '100%',
    height: '100%',
    display: 'flex',
    paddingTop: '56px',
    flexDirection: 'column',
    alignItems: 'flex-start',
  }
});