import {
  Box, Button, Dialog, Typography
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { daDK, DataGrid } from '@mui/x-data-grid';
import lodash from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
import * as orderApi from '../../api/order';
import { CompanyContext } from '../../contexts/companyContext';
import { OrderRequestProgress, OrderStatus } from '../../helpers/enums';
import { formatDate } from '../../helpers/utilities';
import ClosedOrderDialog from '../dialogs/closedOrderDialog';
import MessageDialog from '../dialogs/messageDialog';
import NdaAccept from '../dialogs/ndaAccept';
import UpgradeMembershipDialog from '../dialogs/upgradeMembershipDialog';
import { SearchBar } from '../searchBar';

export default function OrderRequests(props) {
  const classes = useStyles()
  const history = useHistory()
  const company = useContext(CompanyContext)

  const [loading, setLoading] = useState(true)
  const [requests, setRequests] = useState([])
  const [filteredRequests, setFilteredRequests] = useState([])
  const [isNdaOpen, setIsNdaOpen] = useState(false)
  const [ndaToAccept, setNdaToAccept] = useState()
  const [upgradeDialog, showUpgradeDialog] = useState(false)
  const [closedDialog, showClosedDialog] = useState(false)
  const [messageDialog, setMessageDialog] = useState()

  const [search, setSearch] = useState('')
  const [orderBy, setOrderBy] = useState()
  const [direction, setDirection] = useState()

  const columns = [
    { flex: 1, field: 'orderId', headerName: 'Tilbuds ID.', renderCell: (x) => <Box fontWeight={'bold'}>{x.row.orderId}</Box> },
    { flex: 1, field: 'order.company', headerName: 'Kunde', renderCell: (x) => <Box fontWeight={'bold'}>{x.row.order.company}</Box> },
    { flex: 1, field: 'createdAt', headerName: 'Ordredato', valueGetter: (x) => formatDate(new Date(x.row.createdAt), 'PP') },
    { flex: 1, field: 'order.orderSize', headerName: 'Ordrestørrelse', valueGetter: (x) => `${x.row.order.orderSize} stk.` },
    { flex: 1, field: 'order.urgent', headerName: 'Hasteordre?', valueGetter: (x) => x.row.order.urgent ? 'Ja' : 'Nej' },
    { flex: 1, field: 'order.offerExpire', headerName: 'Sidste frist', valueGetter: (x) => x.row.order.offerExpire ? formatDate(new Date(x.row.order.offerExpire), 'PP') : 'Ingen' },
    {
      flex: 1, field: 'order.order_request_comments', headerName: 'Beskeder', minWidth: 150, renderCell: (x) => <>
        {
          x.row.order_request_comments.length > 0
            ? <Button variant='contained' sx={{ minWidth: '121px' }} onClick={(e) => { setMessageDialog(x.row.id); e.stopPropagation(); }}>
              {x.row.order_request_comments.length} Ny besked
            </Button>

            : <Button variant='outlined' sx={{ minWidth: '121px' }} onClick={(e) => { setMessageDialog(x.row.id); e.stopPropagation(); }}>
              Send besked
            </Button>
        }
      </>
    },
    { flex: 1, field: 'rfqType', headerName: 'Type', valueGetter: (x) => x.row.order.rfqType === 'open' ? 'Åben' : 'Direkte' },
    { flex: 1, field: 'progress', headerName: 'Status', minWidth: 175, renderCell: (x) => renderOrderProgress(x.row.progress, x.row.changes, x.row.order.status) },
  ]

  useEffect(() => {
    async function getRequests() {
      setLoading(true)
      const data = await orderApi.getRequests()
      if (data) {
        setRequests(data.rows)
      }
      setLoading(false)
    }
    setOrderBy("createdAt")
    setDirection("desc")
    getRequests()
  }, [])

  useEffect(() => {
    let clone = lodash.cloneDeep(requests)
    let _search = search.toLowerCase()

    if (_search) {
      clone = clone.filter(c =>
        c.orderId === Number(_search) ||
        c.order.company.toLowerCase().includes(_search)
      )
    }

    if (orderBy) {
      if (direction === 'asc') {
        clone = clone.sort((a, b) => lodash.get(a, orderBy) < lodash.get(b, orderBy) ? -1 : 1)
      } else {
        clone = clone.sort((a, b) => lodash.get(a, orderBy) > lodash.get(b, orderBy) ? -1 : 1)
      }
    }

    setFilteredRequests(clone)
  }, [requests, search, orderBy, direction])

  const renderOrderProgress = (progress, changes, status) => {
    let color = ''
    let text = ''

    switch (progress) {
      case OrderRequestProgress.NEW:
        color = '#D2FF52'
        text = 'Ny'
        break
      case OrderRequestProgress.OFFER_SENT:
        color = '#DD8500'
        text = 'Afventer kunde'
        break
      case OrderRequestProgress.REJECTED:
        color = '#EB5757'
        text = 'Afvist'
        break
      default:
        color = '#D2FF52'
        text = 'Ny'
        break
    }

    if (changes) {
      color = '#D2FF52'
      text = 'Nye ændringer'
    }

    if (status === OrderStatus.CLOSED) {
      color = '#fff'
      text = 'Afsluttet'
    }

    return <Box sx={{ borderRadius: '5px', py: 1, width: '100%', textAlign: 'center' }} backgroundColor={color}>
      {text}
    </Box>
  }

  const directToOrder = (request) => {
    if (company.info.subscriptionType !== 'PREMIUM' && company.info.subscriptionType !== 'ABSENT') {
      return showUpgradeDialog(true)
    }

    if (request.order.status === OrderStatus.CLOSED && request.progress !== OrderRequestProgress.OFFER_SENT) {
      return showClosedDialog(true)
    }

    orderApi.hasAccessToRequest(request.orderId)
      .then(response => {
        switch (request.progress) {
          case OrderRequestProgress.NEW:
            history.push(`/request/${request.orderId}`)
            break
          case OrderRequestProgress.NEW_PENDING_CHANGES:
            history.push(`/request/${request.orderId}`)
            break
          case OrderRequestProgress.PENDING_CHANGES:
            history.push(`/order/${request.orderId}`)
            break
          case OrderRequestProgress.REQUEST_SEEN:
            history.push(`/request/${request.orderId}`)
            break
          case OrderRequestProgress.OFFER_SENT:
            history.push(`/order/${request.orderId}`)
            break
          case OrderRequestProgress.REJECTED:
            history.push(`/request/${request.orderId}`)
            break
          default:
            break
        }
      }).catch(error => {
        setNdaToAccept(request.orderId)
        setIsNdaOpen(true)
      })
  }

  const onCloseNdaDialog = () => {
    setIsNdaOpen(false)
    setNdaToAccept(null)
  }

  return (
    <React.Fragment>
      <UpgradeMembershipDialog open={upgradeDialog} close={() => showUpgradeDialog(false)} />
      <ClosedOrderDialog open={closedDialog} close={() => showClosedDialog(false)} />

      <Dialog open={isNdaOpen} onClose={onCloseNdaDialog}>
        <NdaAccept orderId={ndaToAccept} onBack={onCloseNdaDialog} />
      </Dialog>

      <SearchBar search={setSearch} />

      <Box sx={{ backgroundColor: 'white', width: '100%' }}>
        <DataGrid
          rows={filteredRequests}
          columns={columns}
          autoHeight={true}
          pageSize={25}
          rowsPerPageOptions={[10, 25, 50, 100]}
          disableColumnMenu={true}
          disableSelectionOnClick={true}
          onRowClick={(x) => directToOrder(x.row)}
          loading={loading}
          localeText={daDK.components.MuiDataGrid.defaultProps.localeText}
        />
      </Box>

      {!loading && filteredRequests.length === 0 &&
        <Box className={classes.noRequests}>
          <Typography variant='h4'>
            Du har ingen forespørgsler.
          </Typography>
        </Box>}

      <MessageDialog requestId={messageDialog} open={Boolean(messageDialog)} close={() => setMessageDialog()} />
    </React.Fragment>
  )
}

const useStyles = makeStyles({
  orderTable: {
    width: '100%',
    minWidth: '1050px',
    borderCollapse: 'collapse',
    background: 'white'
  },
  tableHeader: {
    backgroundColor: '#061B37',
  },
  tableRow: {
    cursor: 'pointer'
  },
  noRequests: {
    width: '100%',
    height: '400px',
    background: 'white',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column'
  }
})