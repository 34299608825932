import makeStyles from '@mui/styles/makeStyles';

export const pageStyles = makeStyles({
  pageTitle: {
    minHeight: '56px',
    height: '56px',
    width: '100%',
    background: 'white',
    display: 'flex',
    alignItems: 'center',
    padding: '0 24px',
    position: 'fixed',
    top: '0'
  },
})