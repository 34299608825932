import React, { useState, useEffect } from 'react'
import clsx from 'clsx'

import makeStyles from '@mui/styles/makeStyles'
import {
  Box,
  Typography,
  FormControlLabel,
  Checkbox,
  CircularProgress
} from '@mui/material'

import { componentStyles } from '../../styles'
import * as certificationApi from '../../../api/certification'

export default function Certification(props) {
  const classes = useStyles()
  const _classes = componentStyles()

  const [certifications, setCertifications] = useState([]);
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    async function getCertifications() {
      const data = await certificationApi.getAll()
      if (data) {
        setCertifications(data)
      }
      setLoading(false)
    }

    getCertifications()
  }, [])

  return (
    <Box className={clsx(_classes.container, classes.container)}>

      <Typography variant='h5'>
        Certificeringer
      </Typography>

      {loading &&
        <Box display='flex' justifyContent='center' my={10}>
          <CircularProgress />
        </Box>
      }

      {!loading && <Box mt={2}>
        {certifications.sort((a, b) => a.name.localeCompare(b.name)).map(certification => {
          return (
            <FormControlLabel key={certification.id}
              control={
                <Checkbox
                  checked={Boolean(props.companyCertifications.find(m => m.certificationId === certification.id))}
                  onChange={(event) => props.handleChange(event, certification.id)}
                  name={certification.name}
                  color="primary"
                />
              }
              label={certification.name}
              style={{ marginBottom: '5px', width: '100%' }}
            />
          )
        })}
      </Box>}

    </Box>
  )
}

const useStyles = makeStyles({
  container: {
    width: '100%',
    marginBottom: '15px'
  },
});