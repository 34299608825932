import {
  Box, Typography
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import React from 'react'
import * as offerApi from '../../api/offer'
import { saveAs } from 'file-saver'

export default function AttachedFiles(props) {
  const classes = useStyles()

  const downloadFile = () => {
    if (props.isNotAttached) return
    offerApi.getOfferFile(props.offerId)
      .then(function (response) {
        saveAs(new Blob([response.data]), props.offerName)
      })
  }

  return (
    <Box className={classes.container} mr={3} p={2}>
      <Typography variant='h5'>
        Tilbud
      </Typography>

      <Box onClick={() => downloadFile()}>
        {props.isNotAttached &&
          <Typography>
            Ikke vedhæftet
          </Typography>
        }
        {!props.isNotAttached &&
          <Typography style={{ textDecoration: 'underline', color: '#7B61FF', cursor: 'pointer' }}>
            {props.offerName}
          </Typography>
        }
      </Box>

    </Box>
  )
}

const useStyles = makeStyles({
  container: {
    width: '50%',
    minHeight: '185px',
    border: '2px solid #DD8500',
    borderRadius: '5px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center'
  }
});