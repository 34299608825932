import React, { useState, useEffect } from 'react'
import makeStyles from '@mui/styles/makeStyles'
import {
  Box, Typography, Button
} from '@mui/material'

import { AddFileButton } from '../addFileButton'

export default function OfferFile(props) {
  const classes = useStyles()
  const [file, setFile] = useState(props.file)
  const editFile = props.file

  useEffect(() => {
    setFile(editFile);
  }, [editFile])

  const uploadFile = async (file) => {
    if (props.editOffer) {
      props.editOffer.oldFile = null;
    }
    setFile(file)
    props.setFile(file)
  }

  const removeFile = () => {
    setFile(null)
    props.setFile(null)
  }

  return (
    <Box className={classes.container} px={2} style={{ borderColor: props.error && !file ? 'red' : '' }}>
      <Typography variant='h5'>
        Vedhæft tilbud
      </Typography>

      <Typography variant='body2'>
        {file ? file.name : 'Tryk på "Gennemse" for at vedhæfte et tilbud'}
      </Typography>

      <Box>
        {!file && <AddFileButton uploadFile={uploadFile} />}

        {file && <Button variant='contained' color='red' onClick={() => removeFile()}>
          Fjern
        </Button>}
      </Box>
    </Box>
  )
}

const useStyles = makeStyles({
  container: {
    width: '50%',
    height: '200px',
    border: '2px solid #DD8500',
    borderRadius: '5px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    textAlign: 'center',
    marginRight: '24px',
    '@media (max-width: 1350px)': {
      marginRight: '5px',
    }
  }
});