import React, { useState } from 'react'

import {
  Box,
  Typography,
} from '@mui/material'

import { componentStyles } from '../../styles'

export default function ProfileText(props) {
  const _classes = componentStyles()
  const [show, setShow] = useState(false)

  return (
    <Box className={_classes.container} mt={3} overflow='hidden'>

      <Typography variant='h5'>
        Profiltekst
      </Typography>

      <Box pl={1} mt={2} overflow='hidden' maxHeight={show ? 'fit-content' : '170px'}>
        <Typography style={{ whiteSpace: "pre-wrap" }}>
          {props.text}
        </Typography>
      </Box>

      <Box textAlign='end' onClick={() => setShow(!show)} style={{ cursor: 'pointer' }}>
        <Typography color='primary' variant='h6'>{show ? 'Vis mindre' : 'Vis mere'}</Typography>
      </Box>
    </Box>
  )
}