import {
  Box, Tab, Tabs, Typography
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { useSnackbar } from 'notistack'
import React, { useContext, useEffect, useState } from 'react'
import EmailSettings from '../components/user/emailSettings'
import UserInfo from '../components/user/userInfo'
import Settings from '../containers/company/settings'
import { CompanyContext } from '../contexts/companyContext'
import { useQuery } from '../helpers/hooks'
import { pageStyles } from './styles'

function SettingsPage() {
  const classes = useStyles()
  const _classes = pageStyles()
  const { enqueueSnackbar } = useSnackbar();
  const query = useQuery()
  const company = useContext(CompanyContext)

  const [value, setValue] = useState(0);

  useEffect(() => {
    if (query.get('success') === '1' && !company.info?.subscription?.customerId) {
      company.getCompanyInfo()
    }
  }, [query, company])

  useEffect(() => {
    if (query.get('success') === '1') {
      enqueueSnackbar('Medlemskab opdateret', { variant: 'success' });
    }

    // eslint-disable-next-line
  }, [])

  if (company.loading) {
    return <Box />
  }

  return (
    <Box className={classes.container}>
      <Box className={_classes.pageTitle}>
        <Typography variant='h3'>Indstillinger</Typography>
      </Box>

      <Box p={3} pt={2} width='100%' style={{ overflowY: 'scroll' }}>
        <Tabs value={value} onChange={(e, value) => setValue(value)} textColor="#000" indicatorColor="primary">
          <Tab label={'Virksomhed'} value={0} />
          <Tab label={'Bruger'} value={1} />
        </Tabs>

        {value === 0 &&
          <Settings />
        }

        {value === 1 &&
          <Box mt={3} display='flex'>
            <UserInfo />

            <EmailSettings />
          </Box>
        }

      </Box>
    </Box>
  )
}

export default SettingsPage

const useStyles = makeStyles({
  container: {
    width: '100%',
    height: '100%',
    display: 'flex',
    paddingTop: '56px',
    flexDirection: 'column',
    alignItems: 'flex-start',
  }
});