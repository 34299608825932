import React, { useCallback } from 'react'
import { useDropzone } from 'react-dropzone'

import {
  Button,
} from '@mui/material'

export function AddFileButton(props) {
  const onDrop = useCallback(acceptedFile => {
    props.uploadFile(acceptedFile[0])
  }, [props])

  const { getRootProps, getInputProps } = useDropzone({ onDrop, noDrag: true, maxFiles: 1, accept: '.pdf' })

  return (
    <React.Fragment>

      <div {...getRootProps()} style={{ outline: 'none', width: '100%', height: '100%' }}>
        <input {...getInputProps()} />
        {
          <Button variant='contained'>
            Gennemse
          </Button>
        }
      </div>

    </React.Fragment>
  )
}