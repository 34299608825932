import React, { useCallback, useEffect, useState, useRef } from 'react';
import clsx from 'clsx';

import makeStyles from '@mui/styles/makeStyles';
import {
	Box,
	FormControl,
	FormLabel,
	RadioGroup,
	TextField,
	Typography,
	FormControlLabel,
	Radio,
	Autocomplete,
} from '@mui/material';
import { debounce } from '@mui/material/utils';
import { useMapboxAutofill, useSearchSession } from '@mapbox/search-js-react';

import { componentStyles } from '../../styles';
import { Employees } from '../../../helpers/enums';

export default function GeneralInfo(props) {
	const classes = useStyles();
	const _classes = componentStyles();

	const initialAddress = useRef(props.values.address);

	const [addressSuggestions, setAddressSuggestions] = useState([
		{
			place_name: props.values.address,
		},
	]);

	const session = useSearchSession();
	const mb = useMapboxAutofill({
		accessToken: process.env.REACT_APP_MAPBOX_TOKEN,
	});

	const fetch = React.useMemo(
		() =>
			debounce(async (search) => {
				const { suggestions } = await mb.suggest(search, {
					sessionToken: session.sessionToken,
				});

				setAddressSuggestions(suggestions);
			}, 400),
		[mb, session.sessionToken]
	);

	useEffect(() => {
		if (props.values.address && props.values.address.length > 3) {
			fetch(props.values.address);
		}
	}, [props.values.address, fetch]);

	const fetchCoordinates = useCallback(
		(suggestion) => {
			(async function () {
				const res = await mb.retrieve(suggestion, {
					sessionToken: session.sessionToken,
				});

				props.handleChange({
					target: {
						name: 'coordinatesLongitude',
						value: res.features[0].geometry.coordinates[0],
					},
				});
				props.handleChange({
					target: {
						name: 'coordinatesLatitude',
						value: res.features[0].geometry.coordinates[1],
					},
				});
			})();
		},
		[mb, props, session.sessionToken]
	);

	return (
		<Box
			component="form"
			className={clsx(_classes.container, classes.container)}
		>
			<Typography variant="h5">Generelt</Typography>

			<Box my={3}>
				<TextField
					name={'name'}
					label="Virksomhedens navn"
					variant="outlined"
					fullWidth
					required
					value={props.values.name}
					onChange={props.handleChange}
					error={!props.values.name && props.showError}
				/>
			</Box>

			<Box mb={3} display="flex">
				<TextField
					style={{ marginRight: '30px' }}
					name={'cvr'}
					label="CVR"
					variant="outlined"
					type="number"
					required
					fullWidth
					value={props.values.cvr}
					onChange={props.handleChange}
					error={!props.values.cvr && props.showError}
				/>

				<TextField
					name={'founded'}
					label="Stiftelsesår"
					variant="outlined"
					type="number"
					required
					fullWidth
					value={props.values.founded}
					onChange={props.handleChange}
					error={!props.values.founded && props.showError}
				/>
			</Box>

			<Box mb={3}>
				<Autocomplete
					freeSolo
					getOptionLabel={(option) =>
						typeof option === 'string' ? option : option.place_name
					}
					options={addressSuggestions}
					filterOptions={(options) => options}
					autoComplete
					autoHighlight
					autoSelect
					noOptionsText=""
					popupIcon={null}
					isOptionEqualToValue={(option, value) => {
						if (typeof value === 'string') return option.place_name === value;

						return option.place_name === value.place_name;
					}}
					defaultValue={{
						place_name: initialAddress.current,
					}}
					onInputChange={(e) => {
						if (e?.target?.value && e?.target?.value.length > 3) {
							fetch(e.target.value);
						}
					}}
					onChange={(_e, value) => {
						if (typeof value === 'string') return;

						props.handleChange({
							target: {
								name: 'address',
								value: value?.place_name ? value?.place_name : '',
							},
						});
						props.handleChange({
							target: {
								name: 'postal',
								value: value?.postcode ? value?.postcode : '',
							},
						});
						props.handleChange({
							target: {
								name: 'city',
								value: value?.place ? value?.place : '',
							},
						});
						if (value) {
							fetchCoordinates(value);
						} else {
							setAddressSuggestions([]);
						}
					}}
					renderInput={(params) => (
						<TextField
							{...params}
							name="address"
							label="Adresse"
							error={
								(!props.values.address ||
									!props.values.coordinates ||
									props.values.coordinates.length === 0) &&
								props.showError
							}
							fullWidth
							required
						/>
					)}
				/>
			</Box>

			<Box mt={2} display="flex" justifyContent="space-between">
				<FormControl component="fieldset">
					<FormLabel
						component="legend"
						error={!props.values.employees && props.showError}
					>
						Antal ansatte*
					</FormLabel>
					<RadioGroup
						name={'employees'}
						aria-label="employees"
						value={props.values.employees}
						onChange={props.handleChange}
					>
						{Object.entries(Employees).map((size) => (
							<FormControlLabel
								key={size[0]}
								value={size[0]}
								control={<Radio />}
								label={`${size[1]} ansatte`}
							/>
						))}
					</RadioGroup>
				</FormControl>
			</Box>
		</Box>
	);
}

const useStyles = makeStyles({
	container: {
		width: '32%',
	},
});
