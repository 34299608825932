import React from 'react'

import makeStyles from '@mui/styles/makeStyles'
import {
  Box,
  Button
} from '@mui/material'


export function Actions(props) {
  const classes = useStyles()

  return (
    <Box className={classes.actionsContainer}>
      <Box mr={2}>
        <Button disabled={false} onClick={props.back} variant="contained" color="secondary">
          Tilbage
        </Button>
      </Box>
      <Button variant="contained" onClick={props.next}>
        {props.finished ? 'Færdig' : 'gem og fortsæt'}
      </Button>
    </Box>
  )
}

const useStyles = makeStyles({
  actionsContainer: {
    width: '100%',
    marginTop: '30px',
    display: 'flex',
    justifyContent: 'space-between'
  }
});