import React, { useState } from 'react'

import makeStyles from '@mui/styles/makeStyles'
import {
  Box,
  Typography,
  IconButton,
  Collapse
} from '@mui/material'

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'

import { componentStyles } from '../../styles'
import CheckIcon from '../../../assets/images/check.svg'

export default function Abilities(props) {
  const classes = useStyles()
  const _classes = componentStyles()

  const [open, setOpen] = useState(true)

  const groups = [...new Set(props.data.map(x => x.category))]

  return (
    <Box className={_classes.container} mt={3}>

      <Box display='flex' justifyContent='space-between' alignItems='center'>

        <Typography variant='h5'>
          {props.title}
        </Typography>

        <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
          {open ? <KeyboardArrowUpIcon fontSize='large' /> : <KeyboardArrowDownIcon fontSize='large' />}
        </IconButton>

      </Box>

      <Collapse in={open} timeout="auto" unmountOnExit>
        <Box className={classes.container} p={1} pt={3}>

          {groups.sort().map(group =>
            <Box key={group} className={classes.groupContainer}>
              <Typography variant='h5'>{group}</Typography>

              {props.data.filter(x => x.category === group).map(x =>
                <Box key={group + x.name} mt={2} display='flex' alignItems='center'>
                  <img src={CheckIcon} alt='' style={{ marginRight: '10px', width: '18px', height: '18px' }} />
                  <Typography>{x.name}</Typography>
                </Box>
              )}
            </Box>
          )}

        </Box>
      </Collapse>

    </Box>
  )
}

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  groupContainer: {
    width: '33%',
    marginBottom: '30px'
  }
});