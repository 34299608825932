import React, { useState } from 'react'
import clsx from 'clsx'

import makeStyles from '@mui/styles/makeStyles'
import {
  Box,
  Typography,
  Button
} from '@mui/material'

import { API_ENDPOINT } from '../../../api'
import { componentStyles } from '../../styles'
import { Dropzone } from '../../dropZone'

export default function AddLogo(props) {
  const classes = useStyles()
  const _classes = componentStyles()

  const [logo, setLogo] = useState(props.logo)
  const [preview, setPreview] = useState(null)

  const uploadFile = (files) => {
    props.uploadFile(files)
    setPreview(URL.createObjectURL(files[0]))
  }

  return (
    <Box className={clsx(_classes.container, classes.container)}>
      <Typography variant='h5'>
        Logo
      </Typography>

      <Box my={2}>
        <Typography color={(props.showError && !preview) ? '#E73030' : ''}>
          Upload logo*
        </Typography>

        <Typography variant='subtitle2'>
          Fremgår i søgninger og i oversigter. Vælg filformatet .png med transparrent baggrund for bedste resultat.
        </Typography>
      </Box>

      <Box className={classes.dropzoneContainer}>

        {logo && <img src={`${API_ENDPOINT}/file/image/${logo}`} className={classes.logo} alt='logo' />}
        {preview && <img src={preview} className={classes.logo} alt='logo' />}

        {(logo || preview) &&
          <Box display='flex' justifyContent='center' mt={4}>
            <Button variant='contained' onClick={() => { setLogo(null); setPreview(null); }}>Skift</Button>
          </Box>
        }

        {!logo && !preview &&
          <Box width={'100%'} height={'100%'}>
            <Dropzone uploadFile={uploadFile} />
          </Box>
        }

      </Box>

    </Box>
  )
}

const useStyles = makeStyles({
  container: {
    width: '32%'
  },
  dropzoneContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    flex: '1 1 auto'
  },
  logo: {
    maxWidth: '60%',
  }
});