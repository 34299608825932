import React from 'react'
import { Dialog, Button, Typography, Stack, Box } from '@mui/material'

export default function ClosedOrderDialog(props) {

  return (
    <Dialog open={props.open} onClose={() => props.close()} >
      <Stack gap={4} sx={container} textAlign='center'>
        <Typography variant='h4'>
          Denne forespørgsel er afsluttet
        </Typography>

        <Typography>
          Kunden har afsluttet denne forespørgsel og den kan derfor ikke åbnes.
        </Typography>

        <Box>
          <Typography>
            Kun de forespørgsler I har givet tilbud på kan åbnes efter forespørgslen er afsluttet.
          </Typography>
        </Box>

        <Button variant='contained' onClick={() => props.close()}>
          Okay
        </Button>
      </Stack>
    </Dialog>
  )
}

const container = {
  background: 'radial-gradient(#102D53 0%, #061B37 100%)',
  boxShadow: '0px 5px 15px rgba(0, 0, 0, 0.25)',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '20px',
  color: 'white'
}