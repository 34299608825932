import React, { useState, useEffect } from 'react'
import { Stack, Button, Typography, TextField, Box, FormControlLabel, Checkbox } from '@mui/material'
import * as orderAPI from '../../api/order'
import * as userAPI from '../../api/user'
import { useHistory } from "react-router-dom"
import RejectRequest from './rejectRequest'
import { useSnackbar } from 'notistack'

export default function NdaAccept(props) {
  const history = useHistory()
  const { enqueueSnackbar } = useSnackbar()

  const [accept, setAccept] = useState(false)
  const [name, setName] = useState('')
  const [reject, setReject] = useState(false)
  const [hasOpenedNda, setHasOpenedNda] = useState(false)

  useEffect(() => {
    userAPI.getMe()
      .then(response => {
        setName(response.name)
      })
  }, [])

  const onSubmit = (e) => {
    e.preventDefault();
    if (!hasOpenedNda) {
      enqueueSnackbar('Læs fortrolighedsaftalen før du accepterer', { variant: 'error' });
      return
    }
    orderAPI.acceptNda(props.orderId, name, accept)
      .then(response => {
        history.push(`/request/${props.orderId}`)
      }).catch(error => {
        enqueueSnackbar('noget gik galt', { variant: 'error' });
      })
  }

  const onOpenNda = () => {
    orderAPI.openNda(props.orderId)
    setHasOpenedNda(true)
  }

  const onReject = (comment) => {
    orderAPI.rejectRequest(props.orderId, comment)
      .then(response => {
        history.go(`/orders`)
      })
  }

  if (reject) {
    return <RejectRequest close={() => setReject(false)} rejectRequest={onReject} />
  }

  return (
    <Stack component='form' sx={style} gap={4} onSubmit={onSubmit}>
      <Typography align='center' variant='h3'>
        Godkendelse af fortrolighedsaftale
      </Typography>
      <Typography align='center'>
        Denne forespørgsel er beskyttet af en fortrolighedsaftale. Fortrolighedsaftalen skal godkendes før du kan tilgå filerne.
      </Typography>
      <TextField onChange={e => setName(e.target.value)} value={name} fullWidth required sx={{ background: 'white', borderRadius: 1.5 }} variant='filled' label='Fulde navn'></TextField>

      <Button variant='contained' onClick={onOpenNda}>Læs fortrolighedsaftale</Button>
      <Box display='flex' sx={{ justifyContent: 'center', maxWidth: '400px' }}>
        <FormControlLabel control={<Checkbox style={{ color: 'white' }} name="accept" required checked={accept} onChange={(e) => setAccept(e.target.checked)} />} label='Jeg har læst og accepterer den ovenstående fortrolighedsaftale og erkender, at jeg har bemyndigelse til at acceptere aftalen på virksomhedens vegne.' />
      </Box>

      <Box display='flex' sx={{ justifyContent: 'space-between', width: '100%' }}>
        <Button color='secondary' variant='contained' onClick={props.onBack}>Tilbage</Button>
        <Stack direction="row" gap={2}>
          <Button color='error' variant='contained' onClick={() => setReject(true)}>Afvis</Button>
          <Button color={hasOpenedNda ? 'primary' : 'secondary'} variant='contained' type="submit">Fortsæt</Button>
        </Stack>
      </Box>
    </Stack>
  )
}

const style = {
  background: 'radial-gradient(#102D53 0%, #061B37 100%)',
  boxShadow: '0px 5px 15px rgba(0, 0, 0, 0.25)',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '20px',
  color: 'white'
}