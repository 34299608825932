import React, { createContext, useState, useEffect } from "react"

import { LOGIN_URL } from "../api";
import * as userApi from "../api/user"

export const UserContext = createContext()

export const UserProvider = ({ children }) => {
  const [info, setInfo] = useState({})

  useEffect(() => {
    const getUserInfo = async () => {
      const data = await userApi.getMe()
      if (data) {
        setInfo(data)

        if (!data.email) {
          window.location.replace(`${LOGIN_URL}/authorize/supplier`)
        }
      }
    }
  
    getUserInfo()
  }, [])

  const updateInfo = (info) => {
    setInfo(info)
  }

  return (
    <UserContext.Provider
      value={{
        info,
        updateInfo
      }}
    >
      {children}
    </UserContext.Provider>
  );
};