import React, { useContext, useEffect, useState } from 'react'
import clsx from 'clsx'

import makeStyles from '@mui/styles/makeStyles'
import {
  Box,
  Typography,
  TextField
} from '@mui/material'

import { componentStyles } from '../styles'
import { UserContext } from '../../contexts/userContext'
import * as userApi from '../../api/user'

export default function UserInfo(props) {
  const classes = useStyles()
  const _classes = componentStyles()

  const user = useContext(UserContext)

  const [values, setValues] = useState({
    name: '',
    phone: '',
    email: ''
  })

  const handleChange = (event) => {
    setValues({ ...values, [event.target.name]: event.target.value });
  };

  const handleBlur = () => {
    userApi.update(values)
    user.updateInfo(values)
  }

  useEffect(() => {
    setValues(user.info)
  }, [user.info])

  return (
    <Box className={clsx(_classes.container, classes.container)} mr={3}>
      <Typography variant='h5'>
        Mine oplysninger
      </Typography>

      <Box my={3}>
        <TextField
          name={'name'}
          label="Navn"
          variant="outlined"
          fullWidth
          value={values.name}
          onBlur={handleBlur}
          onChange={handleChange}
        />
      </Box>

      <Box mb={3}>
        <TextField
          name={'phone'}
          label="Tlf."
          variant="outlined"
          fullWidth
          value={values.phone}
          onBlur={handleBlur}
          onChange={handleChange}
        />
      </Box>

      <Box mb={3}>
        <TextField
          name={'email'}
          label="Email"
          variant="outlined"
          fullWidth
          value={values.email}
          onBlur={handleBlur}
          onChange={handleChange}
          disabled
        />
      </Box>

    </Box>
  )
}

const useStyles = makeStyles({
  container: {
    width: '40%',
    maxWidth: '400px'
  }
});