import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import makeStyles from '@mui/styles/makeStyles'
import { Box, Typography, Button, Stack, Accordion, AccordionSummary, AccordionDetails } from '@mui/material'

import * as orderApi from '../../api/order'
import * as offerApi from '../../api/offer'
import * as fileApi from '../../api/file'
import { FileTable } from '../../components/offers/fileTable'
import CustomerInfo from '../../components/orders/customerInfo'
import Messages from '../../components/orders/messages'
import CreateOffer from './createOffer'
import RejectRequestDialog from '../../components/dialogs/rejectRequestDialog'
import { OrderRequestProgress } from '../../helpers/enums';

export default function OrderRequest(props) {
  const classes = useStyles()

  const [order, setOrder] = useState(null)
  const [offerDraftExtraItems, setOfferDraftExtraItems] = useState([])
  const [offerDraftPrices, setOfferDraftPrices] = useState({})
  const [loading, setLoading] = useState(true)
  const [createOffer, setCreateOffer] = useState(false)
  const [rejectDialog, showRejectDialog] = useState(false)

  const history = useHistory()

  useEffect(() => {
    getRequest()
    getDraft()

    // eslint-disable-next-line
  }, [props.orderRequestId])

  async function getDraft() {
    const items = await offerApi.getDraft(props.orderRequestId)

    if (items) {
      setOfferDraftExtraItems(items.filter(item => !item.orderItemId))
      setOfferDraftPrices(Object.fromEntries(
        items.filter(item => item.orderItemId).map(item => [item.orderItemId, item.price])
      ))
    }
  }

  async function getRequest() {
    setLoading(true)
    const data = await orderApi.getRequest(props.orderRequestId)

    if (data === 'Payment Required') {
      return history.push('/orders')
    }

    if (data) {
      setOrder(data)
    }

    setLoading(false)
  }

  const rejectRequest = async (comment) => {
    await orderApi.rejectRequest(props.orderRequestId, comment)
    history.push('/orders')
  }

  if (loading) {
    return <Box />
  }

  const onCreateOffer = () => {
    setCreateOffer(true)
  }

  const handleBack = () => {
    getDraft()
    setCreateOffer(false)
  }

  if (createOffer) {
    return <CreateOffer
      orderId={props.orderRequestId}
      order={order}
      offerDraftExtraItems={offerDraftExtraItems}
      offerDraftPrices={offerDraftPrices}
      back={handleBack}
    />
  }

  return (
    <Box>
      <Box display='flex'>
        <Stack gap={2} width={'66%'}>
          <Box className={classes.container}>
            <Messages requestId={order.id} />
          </Box>

          <Box className={classes.container}>
            <FileTable
              orderId={props.orderRequestId}
              orderItems={order.orderItems}
              orderEdits={order.orderEdits}
            />
          </Box>

          <Box display='flex' alignItems='center' justifyContent='space-between' width='100%'>
            <Box>
              <Button variant='contained' onClick={() => fileApi.getOrderRequestFiles(props.orderRequestId)}>
                Download filer
              </Button>
            </Box>

            <Box className={classes.container} width='fit-content' p={2}>
              <Typography variant='h5'>
                Ordrestørrelse: {order.orderItems.reduce((a, b) => Number(a) + Number(b.quantity), 0)} stk.
              </Typography>
            </Box>
          </Box>

        </Stack>

        <Box width={'34%'} ml={2}>
          <Accordion defaultExpanded={true} elevation={0} sx={accordionStyle}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} >
              <ShoppingCartIcon />
              <Typography pl={1} variant='h5'>Ordreinformation</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <CustomerInfo nda={order.nda} orderId={order.orderInfo.id} values={order.orderInfo} orderEdits={order.orderEdits} />
            </AccordionDetails>
          </Accordion>
        </Box>
      </Box>

      <Box display='flex' justifyContent='space-between' mt={3}>

        <Button variant='contained' color='secondary' onClick={() => history.push('/orders')}>
          Tilbage
        </Button>

        {order.progress !== OrderRequestProgress.REJECTED && <Box display='flex'>

          <Button variant='contained' color='red' onClick={() => showRejectDialog(true)}>
            Afvis forespørgsel
          </Button>

          <Box ml={3}>
            <Button variant='contained' onClick={() => onCreateOffer()}>
              Opret tilbud
            </Button>
          </Box>

        </Box>}
      </Box>

      <RejectRequestDialog open={rejectDialog} close={() => showRejectDialog(false)} rejectRequest={rejectRequest} />

    </Box>
  )
}

const accordionStyle = {
  boxShadow: '0px 5px 15px rgba(0, 0, 0, 0.2)',
  borderRadius: '5px'
}
const useStyles = makeStyles({
  container: {
    boxShadow: '0px 5px 15px rgba(0, 0, 0, 0.2)',
    borderRadius: '5px',
    backgroundColor: 'white'
  },
  commentField: {
    border: '1px solid #C7C7C7',
    borderRadius: '5px',
    minHeight: '100px'
  }
});