import axios from 'axios'
import { SUPPLIER_ENDPOINT, buildApiHeaders, API_ENDPOINT } from '.'

const API_ROUTE = `${SUPPLIER_ENDPOINT}/company`

export async function getMe() {
  const url = `${API_ROUTE}/me`
  const response = await axios.get(url, { headers: buildApiHeaders() })
    .catch(err => err.response)

  return response ? response.data : null
}

export async function getProfile() {
  const url = `${API_ROUTE}/profile`
  const response = await axios.get(url, { headers: buildApiHeaders() })
    .catch(err => err.response)

  return response ? response.data : null
}

export async function createCompany(body) {
  const url = `${API_ROUTE}/create`

  const response = await axios.post(url, body, { headers: buildApiHeaders() })
    .catch(err => err.response)

  return response ? response.data : null
}

export async function updateCompany(body) {
  const url = `${API_ROUTE}/update`

  const response = await axios.post(url, body, { headers: buildApiHeaders() })
    .catch(err => err.response)

  return response ? response.data : null
}

export async function updateMethods(methods) {
  const url = `${API_ROUTE}/methods`

  const response = await axios.post(url, { methods }, { headers: buildApiHeaders() })
    .catch(err => err.response)

  return response ? response.data : null
}

export async function updateMaterials(materials) {
  const url = `${API_ROUTE}/materials`

  const response = await axios.post(url, { materials }, { headers: buildApiHeaders() })
    .catch(err => err.response)

  return response ? response.data : null
}

export async function addLogo(file) {
  const url = `${API_ROUTE}/logo`

  const formData = new FormData()
  formData.append('file', file)

  const config = Object.assign(buildApiHeaders(), { 'content-type': 'multipart/form-data' })

  const response = await axios.post(url, formData, { headers: config })
    .catch(err => err.response)

  return response ? response.data : null
}

export async function updateCertifications(certifications) {
  const url = `${API_ROUTE}/certifications`

  const response = await axios.post(url, { certifications }, { headers: buildApiHeaders() })
    .catch(err => err.response)

  return response ? response.data : null
}

export async function updateIndustries(industries) {
  const url = `${API_ROUTE}/industries`

  const response = await axios.post(url, { industries }, { headers: buildApiHeaders() })
    .catch(err => err.response)

  return response ? response.data : null
}

export async function updateDetails(details) {
  const url = `${API_ROUTE}/details`

  const response = await axios.post(url, { details }, { headers: buildApiHeaders() })
    .catch(err => err.response)

  return response ? response.data : null
}

export async function updateGallery(gallery) {
  const url = `${API_ROUTE}/gallery`

  const response = await axios.post(url, { gallery }, { headers: buildApiHeaders() })
    .catch(err => err.response)

  return response ? response.data : null
}

export async function getGallery() {
  const url = `${API_ROUTE}/gallery`

  const response = await axios.get(url, { headers: buildApiHeaders() })
    .catch(err => err.response)

  return response ? response.data : null
}

export async function getEmailSettings() {
  const url = `${API_ROUTE}/emailSettings`

  const response = await axios.get(url, { headers: buildApiHeaders() })
    .catch(err => err.response)

  return response ? response.data : null
}

export async function updateEmailSettings(settings) {
  const url = `${API_ROUTE}/emailSettings`

  const response = await axios.post(url, settings, { headers: buildApiHeaders() })
    .catch(err => err.response)

  return response ? response.data : null
}

export async function getTradeTerms() {
  const url = `${API_ROUTE}/terms`
  return axios.get(url, { headers: buildApiHeaders() })
}

export async function routeToTradeTerms() {
  const company = await getProfile()
  if (company.terms.link) {
    let link = company.terms.link
    if (!link.includes("https://")) link = `https://${company.terms.link}`
    window.open(link, '_blank')
  } else if (company.terms.attachment) {
    window.open(`${API_ENDPOINT}/file/image/${company.terms.attachment}`, "_blank")
  } else {
    throw new Error("No terms found")
  }
}

export async function updateTradeTerms(terms) {
  const url = `${API_ROUTE}/terms`

  const formData = new FormData()
  if (terms.type === "link" && terms.link) formData.append('link', terms.link)
  if (terms.type === "link" && !terms.link) formData.append('link', "")
  if (terms.type === "pdf" && terms.file) formData.append("file", terms.file.data)
  if (terms.type === "pdf" && !terms.file) formData.append('link', "")
  const config = Object.assign(buildApiHeaders(), { 'content-type': 'multipart/form-data' })

  return axios.put(url, formData, { headers: config })
}