import React, { useEffect, useState, useContext } from 'react'

import makeStyles from '@mui/styles/makeStyles'
import { Box, Button, CircularProgress, Typography, Stack } from '@mui/material'

import EditIcon from '@mui/icons-material/Edit'
import InfoIcon from '@mui/icons-material/Info'

import { CompanyContext } from '../../contexts/companyContext'
import * as companyApi from '../../api/company'
import * as subscriptionApi from '../../api/subscription'
import Media from '../../components/company/profile/media'
import General from '../../components/company/profile/general'
import ProfileText from '../../components/company/profile/profileText'
import Abilities from '../../components/company/profile/abilities'
import Services from '../../components/company/profile/services'
import { useSnackbar } from 'notistack'
import { useHistory } from 'react-router-dom'
import { useQuery } from '../../helpers/hooks'

export default function Profile(props) {
  const classes = useStyles()
  const companyContext = useContext(CompanyContext)
  const history = useHistory()

  const [company, setCompany] = useState()
  const [loading, setLoading] = useState(true)
  const query = useQuery()
  const { enqueueSnackbar } = useSnackbar()

  useEffect(() => {
    async function getProfile() {
      const data = await companyApi.getProfile()
      if (data) {
        setCompany(data)
      }
      setLoading(false)
    }

    getProfile()
  }, [])

  useEffect(() => {
    if (query.get('success') === '1' && !companyContext.info?.subscription?.customerId) {
      companyContext.getCompanyInfo()
    }
  }, [query, companyContext])

  const publicate = async () => {
    await companyApi.updateCompany({ public: true })
    enqueueSnackbar('Profil offentliggjort', { variant: 'success' });
    companyContext.getCompanyInfo()
  }

  const handleMembership = async () => {
    if (companyContext.info?.subscription?.type) {
      subscriptionApi.checkout(companyContext.info?.subscription?.type, 'company')
    } else {
      history.push('/settings?memberships=true')
    }
  }

  const onViewTradeTerms = async () => {
    companyApi.routeToTradeTerms()
      .catch(function (error) {
        enqueueSnackbar('Ingen handelsbetingelser uploadet. Rediger profil og tilføj handelsbetingelser i trin 4.', { anchorOrigin: { vertical: 'top', horizontal: 'center', }, variant: 'warning' })
      })
  }

  const warningText = () => {
    if (!companyContext.info?.subscription?.customerId || companyContext.info?.subscription?.status !== 'active') {
      return "Profilen er ikke offentlig. Færdiggør opsætning af medlemskab for at publicere profilen."
    }

    return "Profilen er ikke offentlig. Tryk på publicer for at offentliggøre profilen på Supplybase."
  }

  if (loading) {
    return <Box className={classes.container}>
      <CircularProgress />
    </Box>
  }
  return (
    <Box width={'100%'}>
      <Box className={classes.container}>

        {!companyContext.info.public && <Box className={classes.public} p={2} mb={2}>
          <InfoIcon color={'white'} fontSize='large' />
          <Box ml={2}>
            <Typography>
              {warningText()}
            </Typography>
          </Box>
        </Box>}

        <Box className={classes.actions}>
          <Stack direction='row' spacing={2}>
            <Button variant='contained' startIcon={<EditIcon />} onClick={() => props.setEdit(true)}>Rediger profil</Button>
            <Button variant='outlined' onClick={onViewTradeTerms}>Handelsbetingelser</Button>
          </Stack>

          {!companyContext.info.public && <>
            {(!companyContext.info?.subscription?.customerId || companyContext.info?.subscription?.status !== 'active')
              ? <Button variant='contained' onClick={handleMembership}>Opsæt medlemskab</Button>
              : <Button variant='contained' onClick={publicate}>Publicer</Button>}
          </>}
        </Box>

        <General data={company.general} certifications={company.certifications} />

        <Media video={company.general.video} />

        <ProfileText text={company.general.description} />

        <Abilities title={'Produktionsmetoder'} data={company.methods.map(m => { return { name: m.method.name, category: m.method.category } })} />

        <Abilities title={'Materialer'} data={company.materials.map(m => { return { name: m.material.name, category: m.material.type } })} />

        {company.details && <Services services={company.details} />}

      </Box>
    </Box>
  )
}

const useStyles = makeStyles({
  container: {
    width: '875px',
    display: 'flex',
    flexDirection: 'column',
    paddingTop: '24px'
  },
  centerBox: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  actions: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between'
  },
  logo: {
    maxWidth: '100%',
    maxHeight: '230px'
  },
  public: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#F36161',
    boxShadow: '0px 3px 5px rgba(0, 0, 0, 0.25)',
    borderRadius: '5px',
    color: 'white'
  }
});