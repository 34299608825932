import React, { useState, useEffect } from 'react'

import {
  Box,
} from '@mui/material'

import { Actions } from './actions'
import { containerStyles } from './styles'

import ProfileText from '../../components/company/edit/profileText'
import Tagline from '../../components/company/edit/tagline'
import Gallery from '../../components/company/edit/gallery'
import Video from '../../components/company/edit/video'
import * as companyApi from '../../api/company'

export default function StepTwo(props) {
  const _classes = containerStyles()
  const [gallery, setGallery] = useState([])
  const [loadGallery, setLoadGallery] = useState(true)
  const [error, setError] = useState(false)

  useEffect(() => {
    async function getGallery() {
      const data = await companyApi.getGallery()
      if (data) {
        setGallery(data.map(x => { return { ...x, name: x.image } }))
      }
      setLoadGallery(false)
    }

    getGallery()
  }, [])

  const next = async () => {
    if (!props.values.description) {
      props.errMsg()
      setError(true)
      return
    }

    const data = await companyApi.updateCompany(props.values)

    if (gallery.length > 0) {
      await companyApi.updateGallery(gallery)
    }

    if (data) {
      props.next()
    }
  }

  return (
    <Box className={_classes.container}>

      <Box display='flex' justifyContent='space-between'>

        <Box justifyContent='space-between' width={'58%'}>
          <Tagline value={props.values.slogan} handleChange={props.handleChange} />
          <ProfileText value={props.values.description} handleChange={props.handleChange} error={error} />
        </Box>

        <Box width={'41%'}>
          <Gallery setGallery={setGallery} gallery={gallery} loading={loadGallery} />
          <Video value={props.values.video} handleChange={props.handleChange} />
        </Box>

      </Box>

      <Actions next={next} back={props.back} />
    </Box>
  )
}