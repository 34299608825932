import React, { createContext, useState, useEffect } from "react"

import * as companyApi from "../api/company"

export const CompanyContext = createContext()

export const CompanyProvider = ({ children }) => {
  const [info, setInfo] = useState({})
  const [noCompany, setNoCompany] = useState(true)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    getCompanyInfo()
  }, [])

  const getCompanyInfo = async () => {
    const data = await companyApi.getMe()
    if (data) {
      setInfo(data)
      setNoCompany(false)
    } else {
      setNoCompany(true)
    }

    setLoading(false)
  }

  return (
    <CompanyContext.Provider
      value={{
        info,
        noCompany,
        getCompanyInfo,
        loading
      }}
    >
      {children}
    </CompanyContext.Provider>
  );
};