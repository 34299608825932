import React from 'react'
import { Typography, Grid, TextField } from '@mui/material'
import { styled } from "@mui/material/styles";

const DisableInput = styled(TextField)(() => ({
  ".MuiInputBase-input.Mui-disabled": {
    WebkitTextFillColor: "#000",
    color: "#000"
  }
}));

export default function OfferNumber(props) {
  return (
    <Grid container alignItems="center" >
      <Grid item xs={6} >
        <Typography variant='h6'>Referencenummer:</Typography>
      </Grid>
      <Grid item xs={6}>
        {(!props.offerNumber && !props.edit) ?
          <TextField disabled={true} size='small' value='Ikke udfyldt'></TextField>
          :
          <DisableInput sx={{ borderColor: "orange" }} disabled={!props.edit} size='small' value={props.offerNumber} onChange={props.onChange}></DisableInput>
        }
      </Grid>
    </Grid>
  )
}