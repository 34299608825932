import React, { useEffect } from 'react'
import { Redirect, useParams } from "react-router-dom"

export default function AuthorizeUser() {
  let { accessToken } = useParams();

  useEffect(() => {
    if (accessToken) {
      localStorage.setItem('jwtToken', accessToken)
    }
  }, [accessToken])

  return (
    <Redirect to="/company" />
  )
}