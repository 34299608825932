import React from 'react'
import clsx from 'clsx'

import makeStyles from '@mui/styles/makeStyles'
import {
  Box,
  Typography,
  TextField
} from '@mui/material'

import { componentStyles } from '../../styles'

export default function ContactInfo(props) {
  const classes = useStyles()
  const _classes = componentStyles()

  return (
    <Box className={clsx(_classes.container, classes.container)}>
      <Typography variant='h5'>
        Kontakt og sociale medier
      </Typography>

      <Box my={3}>
        <TextField
          name={'orderMail'}
          label="Tilbudsmail"
          variant="outlined"
          type="email"
          fullWidth
          required
          value={props.values.orderMail}
          onChange={props.handleChange}
          error={!props.values.orderMail && props.showError}
          helperText="Denne mailadresse vil modtage mails ved kundehenvendelser"
        />
      </Box>

      <Box mb={3}>
        <TextField
          name={'phone'}
          label="Telefonnr."
          variant="outlined"
          fullWidth
          required
          value={props.values.phone}
          onChange={props.handleChange}
          error={!props.values.phone && props.showError}
        />
      </Box>

      <Box mb={3}>
        <TextField
          name={'website'}
          label="Hjemmeside"
          variant="outlined"
          fullWidth
          value={props.values.website}
          onChange={props.handleChange}
        />
      </Box>

      <Box mb={3}>
        <TextField
          name={'linkedIn'}
          label="LinkedIn"
          variant="outlined"
          fullWidth
          value={props.values.linkedIn}
          onChange={props.handleChange}
          helperText="fx dk.linkedin.com/company/supplybasedk"
        />
      </Box>

      <Box mb={3}>
        <TextField
          name={'facebook'}
          label="Facebook"
          variant="outlined"
          fullWidth
          value={props.values.facebook}
          onChange={props.handleChange}
          helperText="fx facebook.com/people/Supplybase-ApS/"
        />
      </Box>

      <Box mb={3}>
        <TextField
          name={'instagram'}
          label="Instagram"
          variant="outlined"
          fullWidth
          value={props.values.instagram}
          onChange={props.handleChange}
          helperText="fx instagram.com/supplybase/?hl=da"
        />
      </Box>

      <Box>
        <TextField
          name={'youtube'}
          label="Youtube"
          variant="outlined"
          fullWidth
          value={props.values.youtube}
          onChange={props.handleChange}
          helperText="fx youtube.com/channel/supplybase"
        />
      </Box>
    </Box>
  )
}

const useStyles = makeStyles({
  container: {
    width: '32%',
  }
});