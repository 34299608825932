import React, { useContext, useEffect, useState } from 'react';

import makeStyles from '@mui/styles/makeStyles';
import {
	Stepper,
	Step,
	StepLabel,
	Typography,
	Box,
	CircularProgress,
	StepButton,
} from '@mui/material';

import { CompanyContext } from '../../contexts/companyContext';
import StepOne from './stepOne';
import StepTwo from './stepTwo';
import StepThree from './stepThree';
import StepFour from './stepFour';
import * as companyApi from '../../api/company';
import * as subscriptionApi from '../../api/subscription';
import * as fileApi from '../../api/file';
import { useSnackbar } from 'notistack';

const steps = [
	'Generelt',
	'Medier og profiltekst',
	'Produktionsmetoder',
	'Yderligere info',
];

function Wizard(props) {
	const classes = useStyles();
	const company = useContext(CompanyContext);
	const { enqueueSnackbar } = useSnackbar();

	const [loading, setLoading] = useState(true);
	const [activeStep, setActiveStep] = useState(0);
	const [companyMethods, setCompanyMethods] = useState([]);
	const [companyMaterials, setCompanyMaterials] = useState([]);
	const [companyCertifications, setCompanyCertifications] = useState([]);
	const [companyIndustries, setCompanyIndustries] = useState([]);
	const [terms, setTerms] = useState({ type: 'link' });

	const [values, setValues] = useState({
		name: '',
		cvr: '',
		founded: '',
		address: '',
		city: '',
		postal: '',
		employees: '',
		website: '',
		orderMail: '',
		phone: '',
		linkedIn: '',
		facebook: '',
		instagram: '',
		youtube: '',
		slogan: '',
		description: '',
		video: '',
		logo: '',
		coordinatesLongitude: '',
		coordinatesLatitude: '',
	});

	const [details, setDetails] = useState({
		minimumOrderSize: -1,
		design: -1,
		privateDeals: -1,
		shipping: -1,
	});

	useEffect(() => {
		async function getProfile() {
			const data = await companyApi.getProfile();
			if (data) {
				setValues(data.general);
				setCompanyMethods(data.methods);
				setCompanyMaterials(data.materials);
				setCompanyCertifications(data.certifications);
				setCompanyIndustries(data.industries);

				data.terms.type = 'link';
				if (data.terms.attachment) {
					data.terms.type = 'pdf';
					const file = await fileApi.getFile(data.terms.attachment);
					console.log(file);
					data.terms.file = file;
				}
				setTerms(data.terms);

				if (data.details) {
					setDetails(data.details);
				}
			}
			setLoading(false);
		}

		getProfile();
	}, []);

	const addLogo = async (file) => {
		const storageId = await fileApi.uploadFile(file[0]);

		setValues({ ...values, logo: storageId });
	};

	const handleDetailChange = (event) => {
		setDetails({ ...details, [event.target.name]: event.target.value });
	};

	const handleChange = (event) => {
		setValues((values) => ({
			...values,
			[event.target.name]: event.target.value,
		}));
	};

	const handleNext = () => {
		if (activeStep < 3) {
			setActiveStep((prevActiveStep) => prevActiveStep + 1);
		} else {
			if (
				company.info?.subscription?.type &&
				!company.info?.subscription?.customerId
			) {
				subscriptionApi.checkout(company.info?.subscription?.type, 'company');
			} else {
				props.setEdit(false);
			}
		}
	};

	const handleBack = () => {
		if (activeStep > 0) {
			setActiveStep((prevActiveStep) => prevActiveStep - 1);
		} else {
			if (!company.noCompany) {
				props.setEdit(false);
			}
		}
	};

	const handleStep = (step) => {
		if (!company.noCompany) {
			setActiveStep(step);
		}
	};

	const showErrMsg = () => {
		enqueueSnackbar('Nogle felter mangler at blive udfyldt', {
			variant: 'error',
		});
	};

	function getStepContent(step) {
		switch (step) {
			case 0:
				return (
					<StepOne
						uploadFile={addLogo}
						handleChange={handleChange}
						values={values}
						back={handleBack}
						next={handleNext}
						errMsg={showErrMsg}
					/>
				);
			case 1:
				return (
					<StepTwo
						handleChange={handleChange}
						values={values}
						back={handleBack}
						next={handleNext}
						errMsg={showErrMsg}
					/>
				);
			case 2:
				return (
					<StepThree
						setCompanyMethods={setCompanyMethods}
						setCompanyMaterials={setCompanyMaterials}
						companyMethods={companyMethods}
						companyMaterials={companyMaterials}
						back={handleBack}
						next={handleNext}
					/>
				);
			case 3:
				return (
					<StepFour
						setCompanyCertifications={setCompanyCertifications}
						setCompanyIndustries={setCompanyIndustries}
						setTerms={setTerms}
						companyCertifications={companyCertifications}
						companyIndustries={companyIndustries}
						terms={terms}
						details={details}
						handleChange={handleDetailChange}
						back={handleBack}
						next={handleNext}
						errMsg={showErrMsg}
					/>
				);
			default:
				return '';
		}
	}

	function SquareStep(props) {
		const { active, icon } = props;

		return (
			<Box
				className={classes.squareStep}
				bgcolor={active ? '#DD8500' : '#C7C7C7'}
			>
				<Typography variant="h5">{icon}</Typography>
			</Box>
		);
	}

	return (
		<Box className={classes.wizardContainer}>
			<Stepper
				activeStep={activeStep}
				nonLinear
				style={{ background: 'inherit', padding: '0' }}
			>
				{steps.map((label, index) => (
					<Step key={label}>
						<StepButton onClick={() => handleStep(index)}>
							<StepLabel StepIconComponent={SquareStep}>
								<Typography variant="h5">{label}</Typography>
							</StepLabel>
						</StepButton>
					</Step>
				))}
			</Stepper>

			{loading && (
				<Box alignSelf="center" mt={20}>
					<CircularProgress />
				</Box>
			)}

			{!loading && getStepContent(activeStep)}
		</Box>
	);
}

export default Wizard;

const useStyles = makeStyles({
	container: {
		width: '100%',
		height: '100%',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'flex-start',
	},
	wizardContainer: {
		display: 'flex',
		flexDirection: 'column',
		paddingTop: '24px',
		minHeight: '500px',
		width: '100%',
	},
	squareStep: {
		width: '30px',
		height: '30px',
		borderRadius: '5px',
		color: 'white',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
});
