import { differenceInCalendarDays, format } from 'date-fns'
import daLocale from 'date-fns/locale/da'

export function handlePriceString(price) {
  if (!price) {
    return "0"
  }

  return price.replace(",", ".")
}

export function formatDate(date1, f, locale) {
  return format(date1, f, { locale: locale || daLocale })
}

export function dayDifference(date1, date2) {
  console.log(differenceInCalendarDays(date1, date2))
  return differenceInCalendarDays(date1, date2)
}

export function renderWebLink(link) {
  if (!link.includes('http')) {
    return 'https://' + link
  }

  return link
}

export function getSubscriptionName(priceId) {
  switch (priceId) {
    case 'price_1M97K3LGTQ9smcRvqKyWuDNl':
    case 'price_1M97GfLGTQ9smcRvFS5ugL2B':
      return 'BASIS Partner'
    case 'price_1M97KOLGTQ9smcRvVego4xte':
    case 'price_1M97FaLGTQ9smcRvZmCyiWXU':
      return 'STEEL Partner'
    case 'price_1M97KvLGTQ9smcRvqXhmwR8X':
    case 'price_1M97IALGTQ9smcRvTKaQVaye':
      return 'STEEL Partner årlig'
    default:
      return 'Ukendt type'
  }
}

export function getPriceId(id) {
  const hostname = window && window.location && window.location.hostname

  if (hostname.indexOf('localhost') > -1 || hostname.indexOf('supplybase-staging') > -1) {
    if (id === 1) return 'price_1M97K3LGTQ9smcRvqKyWuDNl';
    if (id === 2) return 'price_1M97KOLGTQ9smcRvVego4xte';
    if (id === 3) return 'price_1M97KvLGTQ9smcRvqXhmwR8X';
  } else {
    if (id === 1) return 'price_1M97GfLGTQ9smcRvFS5ugL2B';
    if (id === 2) return 'price_1M97FaLGTQ9smcRvZmCyiWXU';
    if (id === 3) return 'price_1M97IALGTQ9smcRvTKaQVaye';
  }
}