import {
  Box, Button, Typography
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import clsx from 'clsx'
import React, { useContext, useState } from 'react'
import { CompanyContext } from '../../../contexts/companyContext'
import { componentStyles } from '../../styles'
import { getSubscriptionName } from '../../../helpers/utilities'
import * as subscriptionApi from '../../../api/subscription'
import { LoadingButton } from '@mui/lab'

export default function Membership(props) {
  const classes = useStyles()
  const _classes = componentStyles()
  const company = useContext(CompanyContext)
  const [loading, setLoading] = useState(false)

  const cancelDowngrade = () => {
    setLoading(true)
    subscriptionApi.cancelDowngrade()
  }

  return (
    <Box className={clsx(_classes.container, classes.container)}>

      <Typography variant='h5'>
        Medlemskab
      </Typography>

      <Box mt={2}>

        {company.info.subscription.type && <Typography>
          Medlemskabstype: {getSubscriptionName(company.info.subscription.type)}
        </Typography>}

        {company.info.subscription.downgradeScheduled && <Typography variant='subtitle2' sx={{ mt: 0.5 }}>
          Dit medlemskab skifter til BASIS Partner d. {new Date(Number(company.info.subscription.downgradeScheduled) * 1000).toLocaleDateString()}
        </Typography>}

        {!company.info.subscription.type && <Box mt={2}>
          <Button variant='contained' onClick={() => props.change(true)}>
            Vælg medlemskab
          </Button>
        </Box>}

        {company.info.subscription.status === 'active' && <Box mt={2}>
          <Button variant='contained' sx={{ mr: 2 }} onClick={() => subscriptionApi.manage()}>
            Håndter medlemskab
          </Button>

          {company.info.subscription.downgradeScheduled
            ? <LoadingButton loading={loading} variant='contained' color='red' onClick={cancelDowngrade}>
              Fortryd BASIS Skift
            </LoadingButton>

            : <Button variant='contained' onClick={() => props.change(true)}>
              Skift medlemskab
            </Button>}
        </Box>}

        {company.info.subscription.status !== 'active' && company.info.subscription.type && <Box mt={2}>
          <Button variant='contained' sx={{ mr: 2 }} onClick={() => subscriptionApi.checkout(company.info.subscription.type, 'settings')}>
            Færdiggør opsætning
          </Button>

          <Button variant='contained' color='secondary' onClick={() => props.change(true)}>
            Skift medlemskab
          </Button>
        </Box>}

      </Box>
    </Box>
  )
}

const useStyles = makeStyles({
  container: {
    marginBottom: '15px',
  },
});