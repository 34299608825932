import React from 'react'
import clsx from 'clsx'

import makeStyles from '@mui/styles/makeStyles'
import {
  Box,
  Typography
} from '@mui/material'

import { componentStyles } from '../../styles'

export default function Support(props) {
  const classes = useStyles()
  const _classes = componentStyles()

  return (
    <Box className={clsx(_classes.container, classes.container)}>

      <Typography variant='h5'>
        Support
      </Typography>

      <Box mt={2}>
        <Typography>
          Er der fejl eller mangler? Skriv til vores support:<br/>support@supplybase.dk
        </Typography>
      </Box>
    </Box>
  )
}

const useStyles = makeStyles({
  container: {
    width: '100%',
    marginBottom: '15px'
  },
});