import React from 'react'

import makeStyles from '@mui/styles/makeStyles'
import DatePicker from '@mui/lab/DatePicker'
import {
  Box, Typography, TextField
} from '@mui/material'

export default function DeliveryDate(props) {
  const classes = useStyles()

  return (
    <Box className={classes.container} px={2}>
      <Typography variant='h5'>
        Leveringsdato
      </Typography>

      <Box>
        <DatePicker
          label="Dato"
          value={props.deliveryDate}
          minDate={new Date()}
          inputFormat={'dd/MM/yy'}
          mask={'__/__/__'}
          onChange={value => props.setDeliveryDate(value)}
          renderInput={(params) => <TextField {...params} error={props.error && (!props.deliveryDate || props.deliveryDate.toString() === "Invalid Date")} />}
        />
      </Box>

      <Typography variant='h5'>
        Reference
      </Typography>

      <Box>
        <TextField
          label="Navn"
          variant="outlined"
          fullWidth
          value={props.reference}
          onChange={event => props.setReference(event.target.value)}
          error={props.error && !props.reference}
        />
      </Box>
    </Box>
  )
}

const useStyles = makeStyles({
  container: {
    width: '50%',
    height: '200px',
    border: '2px solid #DD8500',
    borderRadius: '5px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    textAlign: 'center'
  }
});