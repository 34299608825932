import React, { useState, useContext, useEffect } from 'react'
import clsx from 'clsx'

import makeStyles from '@mui/styles/makeStyles'
import {
  Box,
  Typography,
  TextField,
  Button,
  IconButton,
  CircularProgress,
  MenuItem,
  Menu,
} from '@mui/material'

import SettingsIcon from '@mui/icons-material/Settings'
import PersonIcon from '@mui/icons-material/Person'
import { componentStyles } from '../../styles'
import * as userApi from '../../../api/user'
import { UserContext } from '../../../contexts/userContext'
import admin from '../../../assets/images/admin.png'

export default function Employees(props) {
  const classes = useStyles()
  const _classes = componentStyles()
  const me = useContext(UserContext)

  const [employees, setEmployees] = useState([])
  const [loading, setLoading] = useState(true)
  const [isAdmin, setIsAdmin] = useState(false)
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const [values, setValues] = useState({
    name: '',
    phone: '',
    email: ''
  })

  useEffect(() => {
    get()
  }, [])

  async function get() {
    const data = await userApi.getUserList()

    if (data) {
      setEmployees(data.users)
      setLoading(false)
    }
  }

  useEffect(() => {
    if (employees.length > 0) {
      const myRoles = employees.find(user => user.email === me.info.email)?.roles

      if (myRoles && myRoles.includes('admin')) {
        setIsAdmin(true)
      }
    }
  }, [me, employees])

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const changeAdmin = async (userId) => {
    await userApi.changeAdmin(userId)
    get()
    setIsAdmin(false)
    handleClose()
  }

  const inviteUser = async () => {
    await userApi.inviteEmployee(values)
    get()
  }

  const handleChange = (event) => {
    setValues({ ...values, [event.target.name]: event.target.value });
  }

  const renderEmployee = (user) => {
    return <Box display='flex' justifyContent='space-between' mb={2}>

      <Box width='30%'>
        <Typography style={{ fontWeight: 'bold' }}>Navn:</Typography>
        <Typography noWrap>{user.name}</Typography>
      </Box>

      <Box width='15%'>
        <Typography style={{ fontWeight: 'bold' }}>Tlf.:</Typography>
        <Typography noWrap>{user.phone}</Typography>
      </Box>

      <Box width='35%'>
        <Typography style={{ fontWeight: 'bold' }}>Email:</Typography>
        <Typography noWrap>{user.email}</Typography>
      </Box>

      {user.roles.includes('admin') && <Box>
        <img src={admin} alt='' />
      </Box>}

      {!user.roles.includes('admin') && isAdmin && <IconButton style={{ alignItems: 'flex-end' }} onClick={handleClick}>
        <SettingsIcon />
      </IconButton>}

      {!isAdmin && !user.roles.includes('admin') && <IconButton style={{ alignItems: 'flex-end' }}>
        <PersonIcon />
      </IconButton>}

      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        <MenuItem onClick={() => changeAdmin(user.id)}>Overfør ejerskab</MenuItem>
      </Menu>
    </Box>
  }

  return (
    <Box className={clsx(_classes.container, classes.container)}>

      <Typography variant='h5'>
        Medarbejderprofiler
      </Typography>

      {loading && <Box my={2}>
        <CircularProgress />
      </Box>}

      {!loading && <Box mt={2} mb={1}>
        {React.Children.toArray(
          employees.map(employee => renderEmployee(employee))
        )}
      </Box>}

      <Typography variant='h5'>
        Tilføj medarbejder
      </Typography>

      <Box mt={2}>

        <Box mb={2}>
          <TextField
            name={'name'}
            label="Navn"
            variant="outlined"
            fullWidth
            required
            value={values.name}
            onChange={handleChange}
          />
        </Box>

        <Box mb={2}>
          <TextField
            name={'phone'}
            label="Telefon"
            variant="outlined"
            fullWidth
            required
            value={values.phone}
            onChange={handleChange}
          />
        </Box>

        <Box mb={2}>
          <TextField
            name={'email'}
            label="Email"
            variant="outlined"
            fullWidth
            required
            value={values.email}
            onChange={handleChange}
          />
        </Box>

        <Box display='flex' justifyContent='flex-end'>
          <Button variant='contained' onClick={() => inviteUser()}>
            + Tilføj medarbejder
          </Button>
        </Box>

      </Box>

    </Box>
  )
}

const useStyles = makeStyles({
  container: {
    width: '50%',
    marginBottom: '15px',
  },
});