import React, { useState } from 'react'

import { Box } from '@mui/material'

import { Actions } from './actions'
import { containerStyles } from './styles'

import Certification from '../../components/company/edit/certifications'
import AdditionalInfo from '../../components/company/edit/additionalInfo'
import TradeTerms from '../../components/company/edit/tradeTerms'
import Support from '../../components/company/edit/support'
import * as companyApi from '../../api/company'

export default function StepFour(props) {
  const _classes = containerStyles()
  const [error, showError] = useState(false)

  const handleCertificationChange = (event, id) => {
    if (event.target.checked) {
      props.setCompanyCertifications(props.companyCertifications.concat([{ certificationId: id }]))
    } else {
      props.setCompanyCertifications(props.companyCertifications.filter(m => m.certificationId !== id))
    }
  };

  const next = async () => {
    if (Object.values(props.details).includes(-1)) {
      props.errMsg()
      showError(true)
      return
    }

    await companyApi.updateTradeTerms(props.terms)
    await companyApi.updateCertifications(props.companyCertifications)
    await companyApi.updateDetails(props.details)
    props.next()
  }

  return (
    <Box className={_classes.container}>

      <Box display='flex' justifyContent='space-between'>

        <Box width={'45%'}>
          <Certification companyCertifications={props.companyCertifications} handleChange={handleCertificationChange} />
        </Box>

        <Box width={'35%'}>
          <AdditionalInfo values={props.details} handleChange={props.handleChange} error={error} />
          <TradeTerms terms={props.terms} setTerms={props.setTerms} />
        </Box>

        <Box width={'17%'}>
          <Support />
        </Box>

      </Box>

      <Actions next={next} back={props.back} finished />
    </Box>
  )
}