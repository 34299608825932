import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import makeStyles from '@mui/styles/makeStyles'
import { Box, Typography, Button, Stack, Accordion, AccordionSummary, AccordionDetails } from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton'

import OrderItemsTable from '../../components/orders/orderItemsTable'
import ExtraCostTable from '../../components/orders/extraCostTable'
import CustomerInfo from '../../components/orders/customerInfo'
import DeliveryDate from '../../components/orders/deliveryDate'
import AttachedFiles from '../../components/orders/attachedFiles'
import Messages from '../../components/orders/messages'
import OfferNumber from '../../components/offers/offerNumber'

import * as orderApi from '../../api/order'
import * as fileApi from '../../api/file'
import * as offerApi from '../../api/offer'
import * as companyAPI from '../../api/company'

import NumberFormat from 'react-number-format'
import CreateOffer from './createOffer'

import AcceptCurrentOfferDialog from '../../components/dialogs/acceptCurrentOfferDialog'
import OrderChangesDialog from '../../components/dialogs/orderChangesDialog'

import { OrderRequestProgress } from '../../helpers/enums'

export default function Order(props) {
  const classes = useStyles()
  const history = useHistory()

  const [offer, setOffer] = useState()
  const [order, setOrder] = useState()
  const [pendingChanges, setPendingChanges] = useState(false)
  const [acceptCurrentLoading, setAcceptCurrentLoading] = useState(false)

  const [loading, setLoading] = useState()
  const [editingOffer, setEditingOffer] = useState()
  const [showAcceptCurrentDialog, setShowAcceptCurrentDialog] = useState(false);
  const [showChangesDialog, setShowChangesDialog] = useState(false)

  useEffect(() => {
    const getData = async () => {
      setLoading(true)
      const offerData = await orderApi.getOffer(props.id)
      if (!offerData) return
      const requestData = await orderApi.getRequest(offerData.orderId)
      if (!requestData) return
      setPendingChanges(requestData.progress === OrderRequestProgress.PENDING_CHANGES);
      setOffer(offerData)
      setOrder(requestData)
      setShowChangesDialog(requestData.orderEdits.length > 0);
      setLoading(false)
    }
    getData()
  }, [props.id])

  function acceptCurrent() {
    setAcceptCurrentLoading(true)

    offerApi.acceptCurrent(offer.id)
      .then(function (response) {
        setPendingChanges(false);
        setShowAcceptCurrentDialog(true);
      }).catch(function (error) {
        //Show error message
      }).then(function (response) {
        setAcceptCurrentLoading(false)
      })
  }

  function onNext() {
    setShowAcceptCurrentDialog(false);
    setLoading(false)
  }

  function onNextOrderChanges() {
    setShowChangesDialog(false)
  }

  if (loading || !offer) {
    return <Box />
  }

  const renderOrderStatus = () => {
    if (pendingChanges) {
      return "Status: Nye ændringer"
    }
    if (offer.status === 'open') {
      return "Status: Afventer kunde"
    }

    if (offer.status === 'completed') {
      return "Status: Accepteret"
    }

    if (offer.status === 'benchmarked') {
      return "Status: Benchmark"
    }

    if (offer.status === 'closed') {
      return "Status: Afvist"
    }
  }

  const back = () => {
    if (props.offer) {
      history.push('/orders?status=offers')
    }

    else if (offer.status === 'completed') {
      history.push('/orders?status=completed')
    }

    else {
      history.push('/orders')
    }
  }

  if (editingOffer) {
    return <CreateOffer orderId={props.id} editOffer={offer} order={order} back={() => setEditingOffer(false)} />
  }

  return (
    <Box width={'100%'}>
      <Box display='flex'>
        <Box width={'66%'}>

          <Box className={classes.container}>
            <Messages requestId={order.id} />
          </Box>

          <Box className={classes.container}>
            <OrderItemsTable
              orderId={props.id}
              orderItems={offer.orderItems}
              orderEdits={offer.orderEdits}
            />
          </Box>

          {offer.extraItems && offer.extraItems.length > 0 && <Box className={classes.container} mt={2}>
            <ExtraCostTable
              extraItems={offer.extraItems}
            />
          </Box>}

          <Box display='flex' flexDirection={'column'} alignItems='flex-end' width='100%'>
            <Box display='flex' justifyContent='space-between' width='100%' mt={2}>
              <Box>
                {!props.offer && <Button variant='contained' onClick={() => fileApi.getOrderRequestFiles(props.id)}>
                  Download filer
                </Button>}
              </Box>


              <Box className={classes.container} width='fit-content' p={2} mb={2}>
                <Typography variant='h5'>
                  Ordrestørrelse:
                  <NumberFormat
                    value={offer.orderItems.reduce((a, b) => Number(a) + Number(b.quantity), 0)}
                    displayType={'text'} thousandSeparator={'.'}
                    decimalSeparator={','}
                    suffix={' stk.'}
                    style={{ marginLeft: '5px' }}
                  />
                </Typography>
              </Box>
            </Box>

            <Box className={classes.container} width='fit-content' p={2} ml={2} border='2px solid orange'>
              <Typography variant='h5'>
                {`Total pris ekskl. moms: `}
                <NumberFormat
                  value={Number(offer.total)}
                  displayType={'text'}
                  thousandSeparator={'.'}
                  decimalSeparator={','}
                  decimalScale={2}
                  suffix={' kr.'}
                  style={{ marginLeft: '5px' }}
                />
              </Typography>
            </Box>

          </Box>

        </Box>

        <Box width={'34%'} ml={2}>
          <Accordion defaultExpanded={true} elevation={0} sx={accordionStyle}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} >
              <ShoppingCartIcon />
              <Typography pl={1} variant='h5'>Ordreinformation</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <CustomerInfo nda={order.nda} orderId={offer.orderId} values={offer.customerInfo} orderEdits={offer.orderEdits} />
            </AccordionDetails>
          </Accordion>

          <Box className={classes.container} p={2} mt={2}>
            <OfferNumber offerNumber={offer.offerNumber} />
          </Box>

          <Box className={classes.container} p={2} mt={2}>
            <Typography variant='h5'>
              {renderOrderStatus()}
            </Typography>

            <Box display='flex' mt={2}>
              <AttachedFiles
                isNotAttached={!offer.fileId}
                offerId={offer.id}
                orderId={offer.orderId}
                offerName={offer.fileName}
                status={offer.status}
                offer={props.offer}
              />
              <DeliveryDate deliveryDate={offer.deliveryDate} reference={offer.reference} offer={props.offer} offerId={offer.id} />
            </Box>
            {offer.tradeTerms &&
              <Box mt={1}>
                <Typography onClick={() => companyAPI.routeToTradeTerms()} sx={{ cursor: 'pointer', textDecoration: 'underline', color: '#7B61FF' }} >
                  Handelsbetingelser
                </Typography>
              </Box>
            }
          </Box>
          {
            offer.status === 'open' &&
            <Stack direction="row" mt={2} gap={1} justifyContent="flex-end">
              <Button variant='contained' color='red' onClick={() => setEditingOffer(true)} >Rediger tilbud</Button>
              {
                pendingChanges &&
                <LoadingButton variant='contained' loadingPosition="end" loading={acceptCurrentLoading} onClick={() => acceptCurrent()}>Godkend aktuelle tilbud</LoadingButton>
                /*<Button sx={editButtonStyle} variant='contained' onClick={() => acceptCurrent()} >Godkend aktuelle tilbud</Button>*/
              }
            </Stack>
          }
        </Box>
      </Box>

      <Box display='flex' justifyContent='space-between' mt={3}>

        <Button variant='contained' color='secondary' onClick={back}>
          Tilbage
        </Button>

      </Box>
      <AcceptCurrentOfferDialog open={showAcceptCurrentDialog} onNext={onNext}>
      </AcceptCurrentOfferDialog>
      <OrderChangesDialog open={showChangesDialog} onNext={onNextOrderChanges}></OrderChangesDialog>
    </Box >
  )
}

const accordionStyle = {
  boxShadow: '0px 5px 15px rgba(0, 0, 0, 0.2)',
  borderRadius: '5px'
}

const useStyles = makeStyles({
  container: {
    boxShadow: '0px 5px 15px rgba(0, 0, 0, 0.2)',
    borderRadius: '5px',
    backgroundColor: 'white'
  },
  commentField: {
    border: '1px solid #C7C7C7',
    borderRadius: '5px',
    minHeight: '100px'
  }
});