import { DatePicker } from '@mui/lab'
import {
  Box, Button, TextField, Typography
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import React, { useState } from 'react'
import { formatDate } from '../../helpers/utilities'
import * as orderApi from '../../api/order'
import { useSnackbar } from 'notistack'

export default function DeliveryDate(props) {
  const classes = useStyles()
  const [edit, setEdit] = useState(false)
  const [newDate, setNewDate] = useState(props.deliveryDate)
  const { enqueueSnackbar } = useSnackbar()

  const changeDate = () => {
    if (!newDate || newDate.toString() === "Invalid Date" || newDate < new Date()) {
      enqueueSnackbar('Ugyldig leveringsdato', { variant: 'error' })
      return
    }
    if (formatDate(new Date(newDate), 'PP') !== formatDate(new Date(props.deliveryDate), 'PP')) {
      orderApi.changeDeliveryDate(props.offerId, newDate)
    }

    setEdit(false)
  }

  return (
    <Box className={classes.container} p={2} borderColor={props.offer ? '#C7C7C7' : '#DD8500'}>
      <Typography variant='h5'>
        Leveringsdato
      </Typography>

      {!edit && <React.Fragment>
        <Box mb={1}>
          <Typography variant='h6'>
            {formatDate(new Date(newDate), 'PP')}
          </Typography>
        </Box>

        {!props.offer && <Box mb={2}>
          <Button variant='contained' color='red' onClick={() => setEdit(true)}>
            Rediger
          </Button>
        </Box>}
      </React.Fragment>}

      {edit && <React.Fragment>
        <Box my={1}>
          <DatePicker
            label="Dato"
            value={newDate}
            minDate={new Date()}
            inputFormat={'dd/MM/yy'}
            mask={'__/__/__'}
            onChange={value => setNewDate(value)}
            renderInput={(params) => <TextField {...params} />}
          />
        </Box>

        <Box mb={2}>
          <Button variant='contained' onClick={changeDate}>
            Godkend
          </Button>
        </Box>
      </React.Fragment>}

      <Typography variant='h5'>
        Reference
      </Typography>

      <Box>
        <Typography variant='h6'>
          {props.reference}
        </Typography>
      </Box>
    </Box>
  )
}

const useStyles = makeStyles({
  container: {
    width: '50%',
    minHeight: '185px',
    borderWidth: '2px',
    borderStyle: 'solid',
    borderRadius: '5px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center'
  }
});