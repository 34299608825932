import React, { useState, useEffect, useContext } from 'react'
import { Link, useLocation, Redirect } from "react-router-dom"

import makeStyles from '@mui/styles/makeStyles';
import {
  Box,
  Typography,
  Link as HrefLink
} from '@mui/material'

import logo from '../assets/images/logo.svg'
import noTextLogo from '../assets/images/notextlogo.png'
import CityIcon from '../assets/images/city.png'
import OrderIcon from '../assets/images/clipboard.png'
import SettingsIcon from '../assets/images/settings.png'
import { CompanyContext } from '../contexts/companyContext'
import { LOGIN_URL } from '../api';

const menuItems = [
  {
    name: 'Min virksomhed',
    path: '/company',
    icon: <img src={CityIcon} alt='' />
  },
  {
    name: 'Ordre',
    path: '/orders',
    icon: <img src={OrderIcon} alt='' />
  },
  {
    name: 'Indstillinger',
    path: '/settings',
    icon: <img src={SettingsIcon} style={{ marginBottom: '-3px' }} alt='' />
  }
]

function Header() {
  const classes = useStyles()
  const location = useLocation()
  const [active, setActive] = useState({})
  const company = useContext(CompanyContext)

  useEffect(() => {
    if (location.pathname === '/') {
      setActive(menuItems[0])
    } else {
      setActive(menuItems.find(item => location.pathname === item.path) || {})
    }
  }, [location])

  if (!company.loading && company.noCompany && location.pathname !== '/company') {
    return <Redirect to='/company' />
  }

  return (
    <Box className={classes.header}>

      <HrefLink href={LOGIN_URL}>
        <img src={logo} alt='logo' width='160px' className={classes.hide} />
        <img src={noTextLogo} alt='logo' width={'35px'} className={classes.show} />
      </HrefLink>

      <Box className={classes.menuItems}>
        {company.noCompany
          ? menuItems.map((item, i) => {
            return <Box key={item.name} className={classes.item}>
              <Box display='flex' alignItems={'center'}>
                {item.icon}
              </Box>
              <Typography style={{ opacity: i === 0 ? '1' : '0.5', marginLeft: '16px' }} className={classes.hide}>
                {item.name}
              </Typography>
            </Box>
          })

          : menuItems.map((item, i) => {
            return <Link to={item.path} key={item.name} className={classes.item}>
              <Box >
                {item.icon}
              </Box>
              <Typography
                color={item.name === active.name ? 'primary' : ''}
                style={{ textAlign: 'right', marginLeft: '16px', fontWeight: item.name === active.name ? 'bold' : 'normal' }}
                className={classes.hide}
              >
                {item.name}
              </Typography>
            </Link>
          })}
      </Box>

    </Box>
  )
}

export default Header

const useStyles = makeStyles({
  header: {
    height: '100%',
    width: '260px',
    padding: '15px 30px 25px',
    position: 'fixed',
    top: '0',
    left: '0',
    zIndex: '2',
    display: 'flex',
    flexDirection: 'column',
    background: 'linear-gradient(#061B37, #123667 100%)',
    '@media (max-width: 1250px)': {
      width: '65px',
      padding: '10px 15px'
    }
  },
  menuItems: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    alignItems: 'flex-start',
    borderTop: '1px solid white',
    '@media (max-width: 1250px)': {
      alignItems: 'center',
    }
  },
  item: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '30px',
    fontSize: '20px',
    letterSpacing: '1px',
    cursor: 'pointer',
    color: 'white'
  },
  hide: {
    '@media (max-width: 1250px)': {
      display: 'none'
    }
  },
  show: {
    '@media (min-width: 1250px)': {
      display: 'none'
    }
  }
});