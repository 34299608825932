import { LoadingButton } from '@mui/lab'
import {
  Box,
  Button, Dialog, Typography
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import clsx from 'clsx'
import React, { useState } from 'react'
import * as subscriptionApi from '../../api/subscription'
import { dialogStyles } from './styles'

export default function ConfirmUpgradeDialog(props) {
  const _classes = dialogStyles()
  const classes = useStyles()
  const [loading, setLoading] = useState(false)


  const upgrade = () => {
    setLoading(true)
    subscriptionApi.checkout(props.priceId, "settings")
  }

  return (
    <Dialog open={props.open} onClose={props.close} maxWidth='md'>
      <Box className={clsx(_classes.dialog, classes.dialog)}>

        <Box mb={2}>
          <Typography variant='h3'>
            Ønsker du at opgradere?
          </Typography>
        </Box>

        <Box mb={3}>
          <Typography>
            Dit medlemskab vil blive opgraderet med det samme og du vil blive faktureret for den resterende periode.
          </Typography>
        </Box>

        <Box mb={3}>
          <Typography>
            Tryk fortsæt for at opgradere dit medlemskab
          </Typography>
        </Box>

        <Box display='flex' justifyContent='space-between' width='100%'>
          <Button variant='contained' color='secondary' onClick={() => props.close()}>
            Tilbage
          </Button>

          <LoadingButton loading={loading} variant='contained' onClick={upgrade}>
            Fortsæt
          </LoadingButton>
        </Box>
      </Box>
    </Dialog>
  )
}

const useStyles = makeStyles({
  dialog: {
    padding: '30px',
    textAlign: 'center'
  },
})