const deliveryMethods = {
  0: 'Ordren afhentes hos producenten',
  1: 'Ordren skal sendes',
}

const orderTypes = {
  BENCHMARK: 'Benchmark',
  PROTOTYPE: 'Rapid prototyping',
  TEST: 'Testlevering inden masseproduktion',
  NORMAL: 'Alment indkøb'
}

const employees = {
  micro: '1-10',
  small: '11-30',
  medium: '31-50',
  large: '51-100',
  grand: '100+'
}

const orderSizes = {
  0: '1-10',
  1: '11-20',
  2: '31-50',
  3: '51-100',
  4: '101-200',
  5: '200+',
}

const orderStatus = {
  OPEN: 'open',
  COMPLETED: 'completed',
  BENCHMARKED: 'benchmarked',
  CLOSED: 'closed'
}

const orderRequestStatus = {
  OPEN: 'open',
  COMPLETED: 'completed',
  REJECTED: 'rejected'
}

const orderRequestProgress = {
  NEW: 'new',
  REQUEST_SEEN: 'requestSeen',
  OFFER_SENT: 'offerSent',
  REJECTED: 'rejected',
  PENDING_CHANGES: 'pendingChanges',
  NEW_PENDING_CHANGES: 'newWithChanges'
}

exports.OrderStatus = orderStatus
exports.OrderRequestStatus = orderRequestStatus
exports.Employees = employees
exports.OrderSizes = orderSizes
exports.OrderRequestProgress = orderRequestProgress
exports.DeliveryMethods = deliveryMethods
exports.OrderTypes = orderTypes