import axios from 'axios'
import { API_ENDPOINT, buildApiHeaders } from '.'

const API_ROUTE = `${API_ENDPOINT}/data/method`

export async function getAll() {
  const url = `${API_ROUTE}/all`
  const response = await axios.get(url, { headers: buildApiHeaders() })
    .catch(err => err.response)

  return response ? response.data : null
}