import React, { useState } from 'react'
import clsx from 'clsx'

import makeStyles from '@mui/styles/makeStyles'
import {
  Dialog,
  Box,
  Button,
  Typography,
  TextField
} from '@mui/material'

import { dialogStyles } from './styles'

export default function RejectRequestDialog(props) {
  const _classes = dialogStyles()
  const classes = useStyles()

  const [isButtonsDisabled, setIsButtonDisabled] = useState(false)
  const [comment, setComment] = useState('')

  const rejectRequest = () => {
    if (isButtonsDisabled) return
    setIsButtonDisabled(true)
    props.rejectRequest(comment)
  }

  return (
    <Dialog open={props.open} onClose={props.close} maxWidth='sm'>
      <Box className={clsx(_classes.dialog, classes.dialog)}>

        <Box mb={2}>
          <Typography variant='h4'>
            Er du sikker på at du ønsker at afvise denne forespørgsel?
          </Typography>
        </Box>

        <Box mb={3}>
          <Typography>
            Vi underretter kunden om at I ikke ønsker at give et tilbud på ordren.
          </Typography>
        </Box>

        <Box width='100%' mb={3}>
          <TextField
            style={{ borderRadius: '5px', backgroundColor: 'white' }}
            name="comment"
            placeholder="Skriv en kommentar (Valgfri)"
            variant="outlined"
            value={comment}
            fullWidth
            onChange={(event) => setComment(event.target.value)}
            multiline
            rows={5}
          />
        </Box>

        <Box display='flex' justifyContent='space-between' width='100%'>
          <Button variant='contained' color='secondary' onClick={() => props.close()}>
            Tilbage
          </Button>

          <Button variant='contained' onClick={() => rejectRequest()}>
            Send afvisning
          </Button>
        </Box>

      </Box>
    </Dialog>
  )
}

const useStyles = makeStyles({
  dialog: {
    padding: '20px',
    textAlign: 'center'
  },
})