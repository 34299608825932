import {
  Box,
  Button, Dialog, Typography
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import clsx from 'clsx'
import React from 'react'
import { useHistory } from 'react-router-dom'
import { dialogStyles } from './styles'

export default function UpgradeMembershipDialog(props) {
  const _classes = dialogStyles()
  const classes = useStyles()
  const history = useHistory()

  return (
    <Dialog open={props.open} onClose={props.close} maxWidth='md'>
      <Box className={clsx(_classes.dialog, classes.dialog)}>

        <Box mb={2}>
          <Typography variant='h3'>
            Få adgang til kunden
          </Typography>
        </Box>

        <Box mb={3}>
          <Typography>
            Hvis du ønsker at åbne denne forespørgsel og se kundens filer og kontaktoplysninger, skal du opgradere dit medlemskab.
          </Typography>
        </Box>

        <Box mb={3}>
          <Typography>
            Tryk fortsæt for at opgradere dit medlemskab
          </Typography>
        </Box>

        <Box display='flex' justifyContent='space-between' width='100%'>
          <Button variant='contained' color='secondary' onClick={() => props.close()}>
            Tilbage
          </Button>

          <Button variant='contained' onClick={() => history.push("/settings?memberships=true")}>
            Fortsæt
          </Button>
        </Box>
      </Box>
    </Dialog>
  )
}

const useStyles = makeStyles({
  dialog: {
    padding: '30px',
    textAlign: 'center'
  },
})