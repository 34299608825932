import { React, useEffect, useState, } from 'react'
import clsx from 'clsx'
import NumberFormat from 'react-number-format'

import makeStyles from '@mui/styles/makeStyles'
import { Dialog, Box, Button, Typography } from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton'

import { dialogStyles } from './styles'
import { formatDate } from '../../helpers/utilities'

import { saveAs } from 'file-saver'

export default function SendOfferDialog(props) {
  const _classes = dialogStyles()
  const classes = useStyles()
  const [isLoading, setIsLoading] = useState(false)

  const sendOffer = () => {
    setIsLoading(true)
    props.sendOffer().then(function (data) {
    }).catch(function (error) {

    }).finally(function () {
      setIsLoading(false)
    })
  }

  const renderInfo = (name, value, offerId) => {
    return <Box mb={3} textAlign='center'>
      <Box>
        <Typography>
          {name}:
        </Typography>
      </Box>
      <Box>
        {offerId
          ? <Box onClick={() => saveAs(new Blob([props.file]), props.file.name)} style={{ textDecoration: 'underline', color: '#7B61FF', cursor: 'pointer' }}>
            {value}
          </Box>

          : <Typography variant='h5'>
            {value}
          </Typography>
        }
      </Box>
    </Box>
  }

  return (
    <Dialog open={props.open} onClose={props.close} maxWidth='sm'>
      <Box className={clsx(_classes.dialog, classes.dialog)}>

        <Box mb={3}>
          <Typography variant='h4'>
            Vil du sende tilbuddet?
          </Typography>
        </Box>

        <Box mb={6} className={classes.offerContainer} p={2}>
          <Box>
            {renderInfo('Kunde', props.company)}
            <RenderDate date={props.deliveryDate} />
            {renderInfo('Reference', props.reference)}
            {renderInfo('Tilbud', props.file ? props.file.name : "Ikke vedhæftet", props.file)}
            {renderInfo('Pris', <NumberFormat value={props.total} displayType={'text'} thousandSeparator={'.'} decimalScale={2} decimalSeparator={','} prefix={'DKK '} suffix={',-'} />)}
          </Box>
        </Box>

        <Box display='flex' justifyContent='space-between' width='100%'>
          <Button variant='contained' color='secondary' onClick={() => props.close()}>
            Tilbage
          </Button>
          <LoadingButton loadingPosition="end" variant='contained' loading={isLoading} onClick={() => sendOffer()}>
            Godkend og send
          </LoadingButton>
        </Box>

      </Box>
    </Dialog>
  )
}
function RenderDate(props) {
  const [value, setValue] = useState("")
  useEffect(() => {
    try {
      const formatted = formatDate(new Date(props.date), 'PP')
      setValue(formatted)
    } catch (error) {
    }
  }, [props.date])
  return (
    <Box mb={3} textAlign='center'>
      <Box>
        <Typography>
          Levering
        </Typography>
      </Box>
      <Box>
        <Typography variant='h5'>
          {value}
        </Typography>
      </Box>
    </Box>
  )
}


const useStyles = makeStyles({
  dialog: {
    padding: '20px',
    textAlign: 'center',
    width: '450px'
  },
  offerContainer: {
    width: '385px',
    height: '390px',
    background: '#FFFFFF',
    color: 'black',
    borderRadius: '10px',
  }
})