import React, { useState } from 'react'
import { Box, Button, Typography, TextField } from '@mui/material'

export default function RejectRequest(props) {
  const [isButtonsDisabled, setIsButtonDisabled] = useState(false)
  const [comment, setComment] = useState('')

  const rejectRequest = () => {
    if (isButtonsDisabled) return
    setIsButtonDisabled(true)
    props.rejectRequest(comment)
  }

  return (
    <Box sx={style}>
      <Box mb={2}>
        <Typography align='center' variant='h4'>
          Er du sikker på at du ønsker at afvise denne forespørgsel?
        </Typography>
      </Box>

      <Box mb={3}>
        <Typography>
          Vi underretter kunden om at I ikke ønsker at give et tilbud på ordren.
        </Typography>
      </Box>

      <Box width='100%' mb={3}>
        <TextField
          style={{ borderRadius: '5px', backgroundColor: 'white' }}
          name="comment"
          placeholder="Skriv en kommentar (Valgfri)"
          variant="outlined"
          value={comment}
          fullWidth
          onChange={(event) => setComment(event.target.value)}
          multiline
          rows={5}
        />
      </Box>

      <Box display='flex' justifyContent='space-between' width='100%'>
        <Button variant='contained' color='secondary' onClick={props.close}>
          Tilbage
        </Button>

        <Button variant='contained' onClick={() => rejectRequest()}>
          Send afvisning
        </Button>
      </Box>

    </Box>
  )
}

const style = {
  background: 'radial-gradient(#102D53 0%, #061B37 100%)',
  boxShadow: '0px 5px 15px rgba(0, 0, 0, 0.25)',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '20px',
  color: 'white'
}