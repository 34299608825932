import React, { useState, useEffect } from 'react'
import clsx from 'clsx'

import makeStyles from '@mui/styles/makeStyles'
import {
  Box,
  Typography,
  FormControlLabel,
  Checkbox,
  CircularProgress,
  Collapse,
  IconButton
} from '@mui/material'

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'

import { componentStyles } from '../../styles'
import * as materialApi from '../../../api/material'

export default function Materials(props) {
  const classes = useStyles()
  const _classes = componentStyles()

  const [materials, setMaterials] = useState([])
  const [loading, setLoading] = useState(true)
  const [open, setOpen] = useState(false)

  useEffect(() => {
    async function getMaterials() {
      const data = await materialApi.getAll()
      if (data) {
        setMaterials(data)
      }
      setLoading(false)
    }

    getMaterials()
  }, [])

  const materialGroup = (type) => {
    return (
      <Box mb={2} ml={1}>
        <Typography variant='h5'>
          {type}
        </Typography>

        {materials.filter(m => m.type === type).sort((a, b) => a.name.localeCompare(b.name)).map(material => {
          return (
            <FormControlLabel key={material.id}
              control={
                <Checkbox
                  checked={Boolean(props.companyMaterials.find(m => m.materialId === material.id))}
                  onChange={(event) => props.handleChange(event, material.id)}
                  name={material.name}
                  color="primary"
                />
              }
              label={material.name}
            />
          )
        })}
      </Box>
    )
  }

  return (
    <Box className={clsx(_classes.container, classes.container)}>

      <Box p={'20px'} display='flex' justifyContent='space-between' alignItems='center' onClick={() => setOpen(!open)}>

        <Typography variant='h5'>
          Materialer
        </Typography>

        <IconButton aria-label="expand row" size="small">
          {open ? <KeyboardArrowUpIcon fontSize='large' /> : <KeyboardArrowDownIcon fontSize='large' />}
        </IconButton>

      </Box>

      <Collapse in={open} timeout="auto" unmountOnExit style={{ padding: '0 20px' }}>
        {loading &&
          <Box display='flex' justifyContent='center' my={10}>
            <CircularProgress />
          </Box>
        }

        {!loading && React.Children.toArray(
          [...new Set(materials.map(x => x.type))].map(group => materialGroup(group))
        )}
      </Collapse>

    </Box>
  )
}

const useStyles = makeStyles({
  container: {
    width: '100%',
    marginBottom: '15px',
    cursor: 'pointer',
    padding: 0
  },
});