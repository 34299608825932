import React from 'react'

import {
  Box,
} from '@mui/material'

import { Actions } from './actions'
import { containerStyles } from './styles'

import ServiceGuide from '../../components/company/edit/serviceGuide'
import ProductionMethods from '../../components/company/edit/productionMethods'
import Materials from '../../components/company/edit/materials'
import * as companyApi from '../../api/company'

export default function StepThree(props) {
  const _classes = containerStyles()

  const handleMethodChange = (event, id) => {
    if (event.target.checked) {
      props.setCompanyMethods(props.companyMethods.concat([{ methodId: id }]))
    } else {
      props.setCompanyMethods(props.companyMethods.filter(m => m.methodId !== id))
    }
  };

  const handleMaterialChange = (event, id) => {
    if (event.target.checked) {
      props.setCompanyMaterials(props.companyMaterials.concat([{ materialId: id }]))
    } else {
      props.setCompanyMaterials(props.companyMaterials.filter(m => m.materialId !== id))
    }
  };

  const next = async () => {
    await companyApi.updateMethods(props.companyMethods)
    await companyApi.updateMaterials(props.companyMaterials)
    props.next()
  }

  return (
    <Box className={_classes.container}>

      <Box display='flex' justifyContent='space-between'>

        <Box width={'24%'}>
          <ServiceGuide />
        </Box>

        <Box width={'75%'}>
          <ProductionMethods companyMethods={props.companyMethods} handleChange={handleMethodChange} />
          <Materials companyMaterials={props.companyMaterials} handleChange={handleMaterialChange} />
        </Box>
      </Box>

      <Actions next={next} back={props.back} />

    </Box>
  )
}