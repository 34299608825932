import React, { useContext, useState } from 'react'

import makeStyles from '@mui/styles/makeStyles'
import {
  Box,
  Button,
  Typography,
} from '@mui/material'
import CheckIcon from '../../../assets/images/check_small.svg'
import * as subscriptionApi from '../../../api/subscription'
import { CompanyContext } from '../../../contexts/companyContext'
import { getPriceId } from '../../../helpers/utilities'
import ConfirmUpgradeDialog from '../../dialogs/confirmUpgradeDialog'
import ConfirmDowngradeDialog from '../../dialogs/confirmDowngradeDialog'

export default function ChooseMembership(props) {
  const classes = useStyles()
  const company = useContext(CompanyContext)

  const [upgrade, setUpgrade] = useState()
  const [downgrade, setDowngrade] = useState()

  const selectMembership = async (priceId, type) => {
    if (company.info.subscriptionType === 'BASIS' && company.info?.subscription?.subscriptionId) {
      return setUpgrade(priceId)
    }

    if (type === 'BASIS PARTNER' && company.info?.subscription?.subscriptionId) {
      return setDowngrade(priceId)
    }

    await subscriptionApi.checkout(priceId, "settings")
  }

  const renderMembership = ({ stripe_id, title, subtitle, border, descr, highlightDescr, features, prevIncluded, price, interval, terms, boldTerms, popular }) => {
    return <Box className={classes.membership}
      sx={{ border: popular ? '2px solid #FF6A6A' : '2px solid white', height: popular ? '568px' : '530px' }}>

      {popular && <Box sx={{ backgroundColor: popular ? '#FF6A6A' : '#ECECEC', width: '100%', height: '38px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <Typography color='white' variant='h5'>
          TILBUD! Spar 20%
        </Typography>
      </Box>}

      <Box sx={{ padding: '16px', display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%', width: '100%', alignItems: 'center' }}>
        <Box sx={{ border: border ? '2px solid #DB8500' : '2px solid white', width: '100%', textAlign: 'center', paddingY: '8px', borderRadius: '3px' }}>
          <Typography variant='h4'>
            {title}
          </Typography>

          <Typography variant='h5' fontWeight={'400'}>
            {subtitle}
          </Typography>
        </Box>

        <Box height='75px'>
          {highlightDescr && <Typography fontWeight={'bold'}>
            {highlightDescr}
          </Typography>}

          <Typography>
            {descr}
          </Typography>
        </Box>

        <Box height='110px'>
          {prevIncluded && <Box mb={1}>
            <Typography variant='h6'>+ Alt i basis</Typography>
          </Box>}

          {React.Children.toArray(
            features.map((feature, i) =>
              <Box mt={0.5} display='flex' alignItems='center' key={`feature_${i}`}>
                <img src={CheckIcon} alt='' style={{ marginRight: '10px', width: '18px', height: '18px' }} />
                <Typography fontWeight={'500'}>{feature}</Typography>
              </Box>
            )
          )}
        </Box>

        <Box>
          <Typography variant='h3' fontWeight={'700'} sx={{ mb: 0.5 }}>{price}</Typography>
          <Typography>{interval}</Typography>
          <Typography fontWeight={boldTerms && 'bold'}>{terms}</Typography>
        </Box>

        {company.info?.subscription?.type === stripe_id && <Button variant='contained' disabled>
          aktiv
        </Button>}

        {company.info?.subscription?.type !== stripe_id && <Button variant='contained' onClick={() => selectMembership(stripe_id, title)}>
          Vælg
        </Button>}

      </Box>
    </Box>
  }

  return (
    <Box className={classes.container}>
      {upgrade && <ConfirmUpgradeDialog open={Boolean(upgrade)} close={() => setUpgrade()} priceId={upgrade} />}
      {downgrade && <ConfirmDowngradeDialog open={Boolean(downgrade)} close={() => setDowngrade()} priceId={downgrade} />}

      <Box width='100%' textAlign={'center'}>
        <Typography variant='h3'>Medlemskaber</Typography>
      </Box>

      <Box className={classes.memberships}>
        {renderMembership({
          stripe_id: getPriceId(1),
          title: 'BASIS PARTNER',
          subtitle: 'Ingen binding',
          descr: 'Bliv del af Danmarks digitale fremstillingsindustri, og bliv fundet af nye samarbejdspartnere.',
          features: [
            "Online virksomhedsprofil",
            "Virksomhedsgalleri",
            "Fremvis kompetencer",
            "Bliv underrettet om opgaver"
          ],
          price: 'GRATIS',
          interval: 'de første 3 måneder',
          terms: 'Herefter 89,- per måned',
          boldTerms: true
        })}

        {renderMembership({
          stripe_id: getPriceId(2),
          title: 'STEEL PARTNER',
          subtitle: 'Ingen binding',
          border: true,
          descr: 'Bliv direkte forbundet med kunder der efterspørger jeres specifikke ydelser på Supplybase.',
          features: [
            "Besvar opgaver",
            "Chat direkte med kunder",
            "Først i søgninger"
          ],
          prevIncluded: true,
          price: 'DKK 249,-',
          interval: 'per måned',
          terms: 'ingen bindinger'
        })}

        {renderMembership({
          stripe_id: getPriceId(3),
          title: 'STEEL PARTNER',
          subtitle: '12 måneder',
          border: true,
          highlightDescr: "Spar 20% på dit medlemskab",
          descr: 'og bliv en del af Danmarks digitale fremstillingsindustri i 12 måneder.',
          boldDescr: true,
          features: [
            "Besvar opgaver",
            "Chat direkte med kunder",
            "Først i søgninger"
          ],
          prevIncluded: true,
          price: 'DKK 199,-',
          interval: 'per måned',
          terms: 'ved køb af 12 måneder',
          popular: true
        })}

      </Box>

      <Box display='flex' justifyContent='space-between' mt={3} width='100%'>
        <Button variant='contained' color='secondary' onClick={props.back}>
          Tilbage
        </Button>
      </Box>

    </Box>
  )
}

const useStyles = makeStyles({
  container: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  memberships: {
    marginTop: '12px',
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'flex-end'
  },
  membership: {
    margin: '0px 20px',
    width: '300px',
    backgroundColor: 'white',
    borderRadius: '5px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    textAlign: 'center'
  }
});