import axios from 'axios'
import { saveAs } from 'file-saver'
import { API_ENDPOINT, buildApiHeaders } from '.'

const API_ROUTE = `${API_ENDPOINT}/file`

export async function uploadFile(file) {
  const url = `${API_ROUTE}/upload`

  const formData = new FormData()
  formData.append('file', file)

  const config = Object.assign(buildApiHeaders(), { 'content-type': 'multipart/form-data' })

  const response = await axios.post(url, formData, { headers: config })
    .catch(err => err.response)

  return response ? response.data : null
}

export async function getFile(fileId) {
  const url = `${API_ROUTE}/get`

  return await axios.get(url, {
    params: {
      id: fileId
    },
    headers: buildApiHeaders(),
    responseType: 'blob'
  })
}

export async function getOrderRequestFile(orderId, fileId, fileName) {
  const url = `${API_ROUTE}/orderRequestFile`

  const response = await axios.get(url, {
    params: {
      orderId,
      fileId
    },
    headers: buildApiHeaders(),
    responseType: 'blob'
  }).catch(err => err.response)

  if (response && response.data) {
    saveAs(new Blob([response.data]), fileName)
  }

}

export async function getOrderRequestFiles(orderId) {
  const url = `${API_ROUTE}/orderRequestFiles`

  const response = await axios.get(url, {
    params: {
      orderId
    },
    headers: buildApiHeaders(),
    responseType: 'blob'
  }).catch(err => err.response)

  if (response && response.data) {
    saveAs(new Blob([response.data]), `ordre_${orderId}.zip`)
  }
}

export async function addFile(file) {
  const url = `${API_ROUTE}/add`

  const formData = new FormData()
  formData.append('file', file)

  const config = Object.assign(buildApiHeaders(), { 'content-type': 'multipart/form-data' })

  const response = await axios.post(url, formData, { headers: config })
    .catch(err => err.response)

  return response ? response.data : null
}