import {
  Box, Tab, Tabs, Typography
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import React, { useEffect, useState } from 'react'
import CompletedOrders from '../components/orders/completedOrders'
import OffersSent from '../components/orders/offersSent'
import OrderRequests from '../components/orders/orderRequests'
import OrderRequest from '../containers/orders/orderRequest'
import { useQuery } from '../helpers/hooks'
import { pageStyles } from './styles'

export default function OrdersPage() {
  const classes = useStyles()
  const _classes = pageStyles()
  const query = useQuery()

  const [value, setValue] = useState(0);
  const [orderRequestId, setOrderRequestId] = useState(null)

  useEffect(() => {
    switch (query.get('status')) {
      case 'completed':
        setValue(1)
        break
      case 'offers':
        setValue(2)
        break
      default:
        setValue(0)
    }
  }, [query])

  return (
    <Box className={classes.container}>
      <Box className={_classes.pageTitle}>
        <Typography variant='h3'>Ordrer</Typography>
      </Box>

      <Box width='100%' p={3} style={{ overflowY: 'scroll' }}>
        <Tabs value={value} onChange={(e, value) => setValue(value)} textColor="#000" indicatorColor="primary">
          <Tab label={'Forespørgsler på tilbud'} value={0} />
          <Tab label={'Vundne ordre'} value={1} />
          <Tab label={'Sendte tilbud'} value={2} />
        </Tabs>

        <Box mt={3}>
          {value === 0 &&
            <React.Fragment>
              {!orderRequestId && <OrderRequests setOrderRequestId={setOrderRequestId} />}
              {orderRequestId && <OrderRequest orderRequestId={orderRequestId} />}
            </React.Fragment>
          }

          {value === 1 &&
            <React.Fragment>
              {!orderRequestId && <CompletedOrders setOrderRequestId={setOrderRequestId} />}
              {orderRequestId && <OrderRequest orderRequestId={orderRequestId} />}
            </React.Fragment>
          }

          {value === 2 &&
            <React.Fragment>
              {!orderRequestId && <OffersSent setOrderRequestId={setOrderRequestId} />}
              {orderRequestId && <OrderRequest orderRequestId={orderRequestId} />}
            </React.Fragment>
          }

        </Box>
      </Box>



    </Box>
  )
}

const useStyles = makeStyles({
  container: {
    width: '100%',
    height: '100%',
    display: 'flex',
    paddingTop: '56px',
    flexDirection: 'column',
    alignItems: 'flex-start',
  }
});