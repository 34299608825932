import {
  Box, Dialog
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import React from 'react'
import Messages from '../orders/messages'

export default function MessageDialog(props) {
  const classes = useStyles()

  return (
    <Dialog open={props.open} onClose={props.close} maxWidth='sm' fullWidth>
      <Box>

        <Box className={classes.container}>
          <Messages requestId={props.requestId} />
        </Box>

      </Box>
    </Dialog>
  )
}

const useStyles = makeStyles({
  dialog: {
    padding: '20px',
    textAlign: 'center',
    width: '100%'
  },
  container: {
    boxShadow: '0px 5px 15px rgba(0, 0, 0, 0.2)',
    borderRadius: '5px',
    width: '100%',
    color: 'black',
    backgroundColor: 'white'
  },
})