import React, { useState, useContext } from 'react'

import {
  Box,
} from '@mui/material'

import { Actions } from './actions'
import { containerStyles } from './styles'
import GeneralInfo from '../../components/company/edit/generalInfo'
import AddLogo from '../../components/company/edit/addLogo'
import ContactInfo from '../../components/company/edit/contactInfo'
import { CompanyContext } from '../../contexts/companyContext'

import * as companyApi from '../../api/company'

export default function StepOne(props) {
  const _classes = containerStyles()
  const company = useContext(CompanyContext)
  const [showError, setShowError] = useState(false)

  const next = async () => {
    if (company.noCompany) {
      const data = await companyApi.createCompany(props.values)

      if (data.token) {
        localStorage.setItem('jwtToken', data.token)
        company.getCompanyInfo()
        props.next()
      } else {
        props.errMsg()
        setShowError(true)
      }
    } else {
      const data = await companyApi.updateCompany(props.values)
      
      if (!data || data.message) {
        props.errMsg()
        return setShowError(true)
      }

      props.next()

    }
  }

  return (
    <Box className={_classes.container}>

      <Box display='flex' justifyContent='space-between'>

        <AddLogo uploadFile={props.uploadFile} logo={props.values.logo} showError={showError} />

        <GeneralInfo values={props.values} handleChange={props.handleChange} showError={showError} />

        <ContactInfo values={props.values} handleChange={props.handleChange} showError={showError} />
        
      </Box>

      <Actions next={next} back={props.back} />
    </Box>
  )
}