import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import {
  Box, Button, IconButton, Input, Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow, Typography
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';
import { handlePriceString } from '../../helpers/utilities';


const cells = [
  { id: 'name', label: 'Andet', align: 'left', sortable: true },
  { id: 'price', label: 'Pris', align: 'right', sortable: true },
  { id: 'remove', label: '' },
]

export default function ExtraCostTable(props) {
  const classes = useStyles()

  const handlePriceChange = (row, value) => {
    if (isNaN(handlePriceString(value))) {
      return
    }

    props.setExtraItems(prevState => {
      return Object.assign([], prevState, { [row]: { ...prevState[row], price: value } })
    })
  }

  const handleNameChange = (row, value) => {
    props.setExtraItems(prevState => {
      return Object.assign([], prevState, { [row]: { ...prevState[row], name: value } })
    })
  }

  return (
    <React.Fragment>
      <Table className={classes.fileTable} size='small'>

        <TableHead>
          <TableRow className={classes.tableHeader}>

            {cells.map(cell => (
              <TableCell key={cell.id} align={"center"} style={{ border: '2px solid white' }}>
                <Box color='white' display='flex' justifyContent='center' alignItems='center'>
                  <Typography variant='h6'>{cell.label}</Typography>
                </Box>
              </TableCell>
            ))}

          </TableRow>
        </TableHead>

        <TableBody>

          {props.extraItems.map((item, rowIndex) => (
            <TableRow key={rowIndex}>


              <TableCell width='75%'>
                <Input
                  value={item.name}
                  inputProps={{ className: classes.nameInput }}
                  className={classes.amountContainer}
                  onChange={(e) => handleNameChange(rowIndex, e.target.value)}
                  onBlur={(() => props.updateDraft())}
                />
              </TableCell>

              <TableCell width='20%'>
                <Input
                  value={item.price}
                  inputProps={{ className: classes.priceInput }}
                  className={classes.amountContainer}
                  onChange={(e) => handlePriceChange(rowIndex, e.target.value)}
                  onBlur={(() => props.updateDraft())}
                />
              </TableCell>

              <TableCell width='1%'>
                <IconButton color='red' onClick={() => props.setExtraItems(prev => prev.filter((_, i) => i !== rowIndex))}>
                  <RemoveCircleIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}

          <TableRow>
            <TableCell>
              <Box my={1}>
                <Button variant='contained' onClick={() => props.setExtraItems(prev => [...prev, { name: '', price: '0' }])}>
                  + Tilføj omkostning
                </Button>
              </Box>
            </TableCell>
          </TableRow>

        </TableBody>

      </Table>
    </React.Fragment>
  )
}

const useStyles = makeStyles({
  fileTable: {
    width: '100%',
    borderCollapse: 'collapse',
    background: 'white'
  },
  tableHeader: {
    backgroundColor: '#061B37',
  },
  priceInput: {
    height: '40px',
    border: '2px solid #DD8500',
    textAlign: 'center',
    borderRadius: '5px',
    padding: 0
  },
  nameInput: {
    height: '40px',
    border: '2px solid #DD8500',
    borderRadius: '5px',
    padding: '0 10px'
  },
  amountContainer: {
    width: '90%',
    border: 'none !important',
    '&::before': {
      border: 'none !important'
    },
    '&::after': {
      border: 'none !important'
    }
  }
})