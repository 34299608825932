import React from 'react'

import makeStyles from '@mui/styles/makeStyles'
import {
  Box,
  Typography,
} from '@mui/material'

import { componentStyles } from '../../styles'
import { OrderSizes } from '../../../helpers/enums'

export default function Services(props) {
  const classes = useStyles()
  const _classes = componentStyles()

  const renderService = (name, value) => {
    return <Box className={classes.service} mb={1}>
      <Typography>{name}</Typography>
      <Typography>{value}</Typography>
    </Box>
  } 

  return (
    <Box className={_classes.container} mt={3}>

      <Typography variant='h5'>
        Yderligere information
      </Typography>

      <Box p={1} mt={1}>
        {renderService('Hjælp til design og udvikling', props.services.design ? 'Ja' : 'Nej')}
        {renderService('Mindste ordrestørrelse', `${OrderSizes[props.services.minimumOrderSize]} stk.`)}
        {renderService('Forsendelse til adresse', props.services.shipping ? 'Ja' : 'Nej')}
        {renderService('Handler med privatpersoner', props.services.privateDeals ? 'Ja' : 'Nej')}
      </Box>
    </Box>
  )
}

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  service: {
    display: 'flex',
    justifyContent: 'space-between'
  }
});