import React from 'react'
import clsx from 'clsx'

import makeStyles from '@mui/styles/makeStyles'
import {
  Box,
  Typography,
  Tooltip,
  Link
} from '@mui/material'

import FacebookIcon from '@mui/icons-material/Facebook';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import YouTubeIcon from '@mui/icons-material/YouTube';
import InstagramIcon from '@mui/icons-material/Instagram';

import { API_ENDPOINT } from '../../../api'
import { componentStyles } from '../../styles'
import Enums from '../../../helpers/enums'
import { renderWebLink } from '../../../helpers/utilities';

export default function General(props) {
  const classes = useStyles()
  const _classes = componentStyles()

  function renderGeneral(name, value, tooltip) {
    return <Box display='flex' alignItems='baseline' mb={0.5}>

      <Typography variant='h5'>{name}:</Typography>

      <Tooltip title={tooltip || ""} placement='bottom-start' interactive>
        <Box ml={'5px'} overflow='hidden'>
          <Typography noWrap>{value}</Typography>
        </Box>
      </Tooltip>

    </Box>
  }

  return (
    <Box display='flex' my={3} className={clsx(_classes.container, classes.container)}>

      <Box mr={3} display='flex'>
        {props.data.logo && <img src={`${API_ENDPOINT}/file/image/${props.data.logo}`} className={classes.logo} alt='logo' />}
      </Box>

      <Box overflow='hidden'>
        <Typography variant='h3'>{props.data.name}</Typography>

        {props.data.slogan && <Box mt={0.5} mb={2}>
          <Typography variant='subtitle2'>"{props.data.slogan}"</Typography>
        </Box>}

        {renderGeneral('CVR', props.data.cvr)}
        {renderGeneral('Ansatte', Enums.Employees[props.data.employees])}
        {renderGeneral('Stiftelse', props.data.founded)}
        {renderGeneral('Certificeringer', props.certifications.map(c => c.certification.name.split(" (")[0]).join(", "),
          <Box px={1}>
            {props.certifications.map(c =>
              <Box my={1} key={c.certificationId}>
                <Typography noWrap>
                  {c.certification.name}
                </Typography>
              </Box>
            )}
          </Box>
        )}

        <Box display='flex' mt={0.5}>
          {props.data.facebook && <Link href={renderWebLink(props.data.facebook)} target='_blank'>
            <FacebookIcon fontSize='large' style={{ color: '#2856FC', marginRight: '5px' }} />
          </Link>}
          {props.data.linkedIn && <Link href={renderWebLink(props.data.linkedIn)} target='_blank'>
            <LinkedInIcon fontSize='large' style={{ color: '#5079B7', marginRight: '5px' }} />
          </Link>}
          {props.data.youtube && <Link href={renderWebLink(props.data.youtube)} target='_blank'>
            <YouTubeIcon fontSize='large' style={{ color: '#FF2323', marginRight: '5px' }} />
          </Link>}
          {props.data.instagram &&
            <Link href={renderWebLink(props.data.instagram)} target='_blank'>
              <InstagramIcon fontSize='large' style={{ color: '#E232FF', marginRight: '5px' }} />
            </Link>
          }
        </Box>

      </Box>
    </Box>
  )
}

const useStyles = makeStyles({
  container: {
    flexDirection: 'row',
    overflow: 'hidden'
  },
  logo: {
    maxHeight: '230px',
    maxWidth: '315px',
    margin: 'auto'
  }
});