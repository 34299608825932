import React from 'react'
import clsx from 'clsx'

import makeStyles from '@mui/styles/makeStyles'
import { Box, Typography, FormControlLabel, Radio, RadioGroup, Stack, TextField, Button, Grid, Link } from '@mui/material'

import { componentStyles } from '../../styles'
import { Dropzone } from '../../dropZone'
import { saveAs } from 'file-saver'

export default function TradeTerms(props) {
  const classes = useStyles()
  const _classes = componentStyles()

  const uploadFile = (files) => {
    props.setTerms({ ...props.terms, "file": { data: files[0] }, "fileInfo": { fileName: files[0].name } })
  }

  const download = () => {
    saveAs(new Blob([props.terms.file.data]), props.terms.fileInfo.fileName)
  }

  return (
    <Box className={clsx(_classes.container, classes.container)}>

      <Typography variant='h5'>
        Handelsbetingelser
      </Typography>
      <Box mt={2}>
        <RadioGroup onChange={e => props.setTerms({ ...props.terms, "type": e.target.value })} aria-labelledby="demo-radio-buttons-group-label" value={props.terms.type} name="radio-buttons-group" >
          <Stack direction='row' spacing={1}>
            <FormControlLabel value="link" control={<Radio />} label="Website link" />
            <FormControlLabel value="pdf" control={<Radio />} label="Upload pdf" />
          </Stack>
        </RadioGroup>
        {props.terms.type === 'link' &&
          <TextField fullWidth label="Indsæt link" size='small' value={props.terms.link} onChange={e => props.setTerms({ ...props.terms, "link": e.target.value })} />
        }
        {props.terms.type === 'pdf' &&
          <Box>
            {!props.terms.file && <Dropzone noLogo={true} uploadFile={uploadFile} accept=".pdf"></Dropzone>}
            {props.terms.file &&
              <Grid container spacing={2} direction="column" alignItems="center" justifyContent="center">
                <Grid item xs={12}>
                  <Link onClick={() => download()}>{props.terms.fileInfo.fileName}</Link>
                </Grid >
                <Grid item xs={12}>
                  <Button variant='contained' onClick={() => props.setTerms({ ...props.terms, "file": null, "fileInfo": null })}>Fjern</Button>
                </Grid>
              </Grid>
            }
          </Box>
        }
      </Box>
    </Box>
  )
}

const useStyles = makeStyles({
  container: {
    width: '100%',
    marginBottom: '15px'
  },
});