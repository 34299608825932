import React, { useState, useEffect } from 'react'
import clsx from 'clsx'

import makeStyles from '@mui/styles/makeStyles'
import {
  Box,
  Typography,
  CircularProgress
} from '@mui/material'

import { componentStyles } from '../../styles'
import { Dropzone } from '../../dropZone'
import * as fileApi from '../../../api/file'
import CancelIcon from '../../../assets/images/cancel.png'
import { API_ENDPOINT } from '../../../api'
import * as companyApi from '../../../api/company'

export default function Gallery(props) {
  const classes = useStyles()
  const _classes = componentStyles()

  const [previews, setPreviews] = useState(props.gallery.map(image => {
    return {
      name: image.image,
      url: `${API_ENDPOINT}/file/image/${image.image}`
    }
  }))

  useEffect(() => {
    async function getGallery() {

      const data = await companyApi.getGallery()

      if (data) {
        props.setGallery(data.map(x => { return { ...x, name: x.image } }))

        setPreviews(data.map(image => {
          return {
            name: image.image,
            url: `${API_ENDPOINT}/file/image/${image.image}`
          }
        }))
      }
    }

    getGallery()
    // eslint-disable-next-line
  }, [])

  const uploadFile = async (files) => {
    const newPreviews = files.map(file => {
      return {
        name: file.name,
        url: URL.createObjectURL(file),
        uploaded: storeFile(file)
      }
    })

    setPreviews([...newPreviews, ...previews])
  }

  const removeFile = (name) => {
    setPreviews(previews.filter(p => p.name !== name))
    props.setGallery(props.gallery.filter(file => file.name !== name))
  }

  const storeFile = async (file) => {
    const storageId = await fileApi.uploadFile(file)

    if (storageId) {
      props.setGallery(prevState => [...prevState, { name: file.name, storageId: storageId }])
    }
  }

  return (
    <Box className={clsx(_classes.container, classes.container)}>
      <Typography variant='h5'>
        Galleri
      </Typography>

      {previews.length > 0 && <Box className={classes.imageRow}>
        {previews.map(p => {
          const uploaded = props.gallery.find(image => image.name === p.name)

          return <Box key={p.name} position='relative'>

            {uploaded && <Box className={classes.cancel} onClick={() => removeFile(p.name)}>
              <img src={CancelIcon} width={'22px'} alt='' />
            </Box>}

            {!uploaded && <Box className={classes.uploading}>
              <CircularProgress />
            </Box>}

            <img src={p.url} alt='' className={classes.image} style={{ opacity: uploaded ? 1 : 0.3 }} />

          </Box>
        })}
      </Box>}

      <Box className={classes.dropzoneContainer} mt={previews.length > 0 ? 0 : 4}>
        <Dropzone uploadFile={uploadFile} />
      </Box>

    </Box>
  )
}

const useStyles = makeStyles({
  container: {
    width: '100%',
    height: '440px',
    marginBottom: '15px'
  },
  dropzoneContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    flex: '1 1 auto'
  },
  imageRow: {
    display: 'flex',
    height: '120px',
    margin: '15px 0 5px',
    overflowY: 'hidden'
  },
  image: {
    height: '100px',
    margin: '0 8px'
  },
  uploading: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  cancel: {
    position: 'absolute',
    right: 5,
    zIndex: 2,
    cursor: 'pointer'
  }
});