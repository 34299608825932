import {
  Box, Checkbox, CircularProgress, FormControlLabel, Typography
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import clsx from 'clsx'
import React, { useEffect, useState } from 'react'
import { componentStyles } from '../../styles'
import * as companyApi from '../../../api/company'

const emails = [{
  id: 'orderRequest',
  description: 'Send mail når en kunde forespørger på tilbud'
}, {
  id: 'offerAccepted',
  description: 'Send mail når en kunde accepterer et tilbud'
}, {
  id: 'offerRejected',
  description: 'Send mail når en kunde vælger et andet tilbud'
}, {
  id: 'offerBenchmarked',
  description: 'Send mail når en kunde gemmer et tilbud'
}, {
  id: 'orderRequestChanged',
  description: 'Send mail når en kunde laver ændringer i en forespørgsel'
}, {
  id: 'offerDeadlineReminder',
  description: 'Send mail 3 dage før en tilbudsfrist udløber'
}]

export default function Notifications() {
  const classes = useStyles()
  const _classes = componentStyles()

  const [settings, setSettings] = useState({})
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    async function get() {
      const data = await companyApi.getEmailSettings()

      if (data) {
        setSettings(data)
        setLoading(false)
      }
    }

    get()
  }, [])

  useEffect(() => {
    companyApi.updateEmailSettings(settings)
  }, [settings])

  return (
    <Box className={clsx(_classes.container, classes.container)} mb={3}>

      <Typography variant='h5'>
        Underretninger til ordre mail
      </Typography>

      {loading &&
        <Box mt={2}>
          <CircularProgress />
        </Box>
      }

      {!loading && <Box mt={2}>
        {emails.map(email => {
          return (
            <FormControlLabel key={email.id}
              control={
                <Checkbox
                  checked={Boolean(settings[email.id])}
                  onChange={(event) => { setSettings({ ...settings, [email.id]: event.target.checked }) }}
                  name={email.id}
                  color="primary"
                />
              }
              label={email.description}
              style={{ width: '100%' }}
            />
          )
        })}
      </Box>}

    </Box>
  )
}

const useStyles = makeStyles({
  container: {
    display: 'flex',
  }
});