import React, { useCallback } from 'react'
import { useDropzone } from 'react-dropzone'

import makeStyles from '@mui/styles/makeStyles'
import {
  Box,
  Button,
  Typography
} from '@mui/material'

import DropIcon from '../assets/images/dropIcon.png'
import clsx from 'clsx'

export function Dropzone(props) {
  const onDrop = useCallback(acceptedFile => {
    props.uploadFile(acceptedFile)
  }, [props])
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop, accept: props.accept ? props.accept : '.png,.jpeg,.jpg,.svg,.jfif,.pjpeg,.pjp' })
  const classes = useStyles()

  return (
    <div {...getRootProps()} style={{ outline: 'none', width: '100%', height: '100%' }}>
      <input {...getInputProps()} />
      {
        <Box className={isDragActive ? clsx(classes.dropZone, classes.active) : classes.dropZone}>

          {!props.noLogo && <img src={DropIcon} alt='dropIcon' style={{ width: '65px' }} />}

          <Box my={2}>
            <Typography variant='h5' style={{ color: '#8B8B8B' }} align='center'>
              Træk filer <br />hertil
            </Typography>
          </Box>

          <Button variant='contained'>Gennemse</Button>

        </Box>
      }
    </div>
  )
}

const useStyles = makeStyles({
  dropZone: {
    background: '#E5E5E5',
    border: '2px dashed #DD8500',
    borderRadius: '10px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    outline: 'none',
    cursor: 'pointer',
    width: '100%',
    height: '100%'
  },
  active: {
    borderColor: '#DD8500',
    boxShadow: '0 0 10px #DD8500',
    border: '2px solid #DD8500',
  },
})
