import React from 'react'
import clsx from 'clsx'

import makeStyles from '@mui/styles/makeStyles'
import {
  Box,
  Typography,
  TextField
} from '@mui/material'

import { componentStyles } from '../../styles'

export default function Video(props) {
  const classes = useStyles()
  const _classes = componentStyles()

  return (
    <Box className={clsx(_classes.container, classes.container)}>

      <Typography variant='h5'>
        Præsentationsvideo
      </Typography>

      <Box mt={2}>
        <TextField
          name={'video'}
          label="Indsæt Youtube link"
          variant="outlined"
          placeholder='https://www.youtube.com/watch?v='
          value={props.value || ''}
          fullWidth
          onChange={props.handleChange}
        />
      </Box>

    </Box>
  )
}

const useStyles = makeStyles({
  container: {
    width: '100%'
  },
});