import React from 'react'
import clsx from 'clsx'

import makeStyles from '@mui/styles/makeStyles'
import {
  Box,
  Typography,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio
} from '@mui/material'

import { componentStyles } from '../../styles'
import { OrderSizes } from '../../../helpers/enums'

export default function AdditionalInfo(props) {
  const classes = useStyles()
  const _classes = componentStyles()

  return (
    <Box className={clsx(_classes.container, classes.container)}>

      <Typography variant='h5'>
        Andet
      </Typography>

      <Box mt={2}>
        <FormControl component="fieldset">
          <FormLabel component="legend" error={props.error && props.values.design === -1}>Hjælp til design og udvikling*</FormLabel>
          <RadioGroup name={"design"} value={Number(props.values.design)} onChange={props.handleChange} style={{ flexDirection: 'row' }}>
            <FormControlLabel value={1} control={<Radio />} label="Ja" />
            <FormControlLabel value={0} control={<Radio />} label="Nej" />
          </RadioGroup>
        </FormControl>
      </Box>

      <Box mt={2}>
        <FormControl component="fieldset">
          <FormLabel component="legend" error={props.error && props.values.privateDeals === -1}>Handler med privatpersoner*</FormLabel>
          <RadioGroup name={"privateDeals"} value={Number(props.values.privateDeals)} onChange={props.handleChange} style={{ flexDirection: 'row' }}>
            <FormControlLabel value={1} control={<Radio />} label="Ja" />
            <FormControlLabel value={0} control={<Radio />} label="Nej" />
          </RadioGroup>
        </FormControl>
      </Box>

      <Box mt={2}>
        <FormControl component="fieldset">
          <FormLabel component="legend" error={props.error && props.values.shipping === -1}>Forsendelse til adresse*</FormLabel>
          <RadioGroup name={"shipping"} value={Number(props.values.shipping)} onChange={props.handleChange} style={{ flexDirection: 'row' }}>
            <FormControlLabel value={1} control={<Radio />} label="Ja" />
            <FormControlLabel value={0} control={<Radio />} label="Nej" />
          </RadioGroup>
        </FormControl>
      </Box>

      <Box mt={2}>
        <FormControl component="fieldset">
          <FormLabel component="legend" error={props.error && props.values.minimumOrderSize === -1}>Mindste ordrestørrelse*</FormLabel>
          <RadioGroup name={"minimumOrderSize"} value={Number(props.values.minimumOrderSize)} onChange={props.handleChange} style={{ flexDirection: 'row' }}>
            {Object.entries(OrderSizes).map(size =>
              <FormControlLabel key={size[0]} value={Number(size[0])} control={<Radio />} label={`${size[1]} stk.`} />
            )}
          </RadioGroup>
        </FormControl>
      </Box>

    </Box>
  )
}

const useStyles = makeStyles({
  container: {
    width: '100%',
    marginBottom: '15px'
  },
});