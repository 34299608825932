import React, { useState, useEffect, useContext } from 'react'
import { useHistory } from 'react-router-dom'

import makeStyles from '@mui/styles/makeStyles'
import { Box, Typography, Button, FormControlLabel, Checkbox, Link, Accordion, AccordionSummary, AccordionDetails } from '@mui/material'
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { FileTable } from '../../components/offers/fileTable'
import ExtraCostTable from '../../components/offers/extraCostTable'
import CustomerInfo from '../../components/orders/customerInfo'
import OfferFile from '../../components/offers/offerFile'
import DeliveryDate from '../../components/offers/deliveryDate'
import OfferNumber from '../../components/offers/offerNumber'
import { UserContext } from '../../contexts/userContext'
import * as offerApi from '../../api/offer'
import SendOfferDialog from '../../components/dialogs/sendOfferDialog'
import NumberFormat from 'react-number-format'
import { useSnackbar } from 'notistack'
import * as companyAPI from '../../api/company'
import Messages from '../../components/orders/messages';
import { handlePriceString } from '../../helpers/utilities';

export default function CreateOffer(props) {
  const classes = useStyles()
  const user = useContext(UserContext)
  const history = useHistory()
  const { enqueueSnackbar } = useSnackbar();

  const editOffer = props.editOffer;
  const order = props.order
  const [orderItemPrices, setOrderItemPrices] = useState(props.offerDraftPrices || {})
  const [extraItems, setExtraItems] = useState(props.offerDraftExtraItems || [])
  const [deliveryDate, setDeliveryDate] = useState(null)
  const [file, setFile] = useState(null);
  const [total, setTotal] = useState(0)
  const [offerNumber, setOfferNumber] = useState(null)
  const [reference, setReference] = useState(user.info.name)
  const [tradeTerms, setTradeTerms] = useState(false)
  const [validating, setValidating] = useState(false)
  const [sendOfferDialog, showSendOfferDialog] = useState(false)
  const [error, showError] = useState(false)

  useEffect(() => {
    if (editOffer) {
      let orderItems = {}
      editOffer.orderItems.forEach((element) => { orderItems[element.id] = element.price });
      console.log(editOffer)
      setDeliveryDate(editOffer.deliveryDate);
      setOrderItemPrices(orderItems);
      setExtraItems(editOffer.extraItems)
      setReference(editOffer.reference);
      setTradeTerms(editOffer.tradeTerms)
      setOfferNumber(editOffer.offerNumber)
      if (editOffer.tradeTerms === "ATTACHED") {
        offerApi.getOfferFile(editOffer.id)
          .then(function (response) {
            response.name = editOffer.fileName;
            editOffer.oldFile = response;
            setFile(response);
          })
      }
    }
  }, [editOffer])

  useEffect(() => {
    setTotal(order.orderItems.reduce((a, b) =>
      Number(a) + Number(b.quantity) * handlePriceString(orderItemPrices[b.id]), 0) + extraItems.reduce((a, b) => Number(a) + Number(handlePriceString(b.price)), 0))
  }, [order.orderItems, orderItemPrices, extraItems])

  useEffect(() => {
    const orderPrices = {}

    order.orderItems.forEach(item => {
      if (item.quantity <= 0) orderPrices[item.id] = '0'
    })

    setOrderItemPrices(prev => ({ ...prev, ...orderPrices }))
  }, [order.orderItems])

  const updateDraft = async () => {
    if (editOffer) {
      return
    }

    offerApi.updateDraft(props.orderId, orderItemPrices, extraItems)
  }

  const handlePriceUpdate = (event, id) => {
    const value = handlePriceString(event.target.value)
    if (!isNaN(value)) {
      setOrderItemPrices({ ...orderItemPrices, [id]: event.target.value });
    }
  };

  const validateOffer = async () => {
    if (validating) return
    setValidating(true)

    const priceKeys = Object.keys(orderItemPrices);
    if (!reference || priceKeys.length !== order.orderItems.length) {
      enqueueSnackbar('Nogle felter mangler at blive udfyldt', { variant: 'error' })
      showError(true)
      return
    }

    for (let i = 0; i < priceKeys.length; i++) {
      const price = orderItemPrices[priceKeys[i]];
      if (isNaN(handlePriceString(price))) {
        enqueueSnackbar('Nogle felter mangler at blive udfyldt', { variant: 'error' })
        showError(true)
        return
      }
    }

    if (!deliveryDate || deliveryDate.toString() === "Invalid Date" || deliveryDate < new Date()) {
      enqueueSnackbar('Ugyldig leveringsdato', { variant: 'error' })
      showError(true)
      return
    }

    if (!tradeTerms && !file) {
      enqueueSnackbar('Vedhæft et tilbud som PDF eller tilføj handelsbetingelser', { variant: 'error' })
      showError(true)
      return
    }

    const profile = await companyAPI.getProfile()
    if (tradeTerms && !profile.terms.link && !profile.terms.attachment) {
      enqueueSnackbar('Ingen handelsbetingelser uploadet. Rediger profil og tilføj handelsbetingelser i trin 4.', { variant: 'error' })
      showError(true)
      return
    }

    showSendOfferDialog(true)
  }

  const onViewTradeTerms = () => {
    companyAPI.routeToTradeTerms()
      .catch(function (error) {
        enqueueSnackbar('Ingen handelsbetingelser uploadet. Rediger profil og tilføj handelsbetingelser i trin 4.', { anchorOrigin: { vertical: 'top', horizontal: 'center', }, variant: 'warning' })
      })
  }

  const sendOffer = async () => {
    const realOfferNumber = offerNumber ? offerNumber : null;

    const formatItemPrices = { ...orderItemPrices }
    Object.keys(formatItemPrices).map(key => formatItemPrices[key] = handlePriceString(formatItemPrices[key]))

    const formatExtraItemPrices = extraItems.map(item => {
      return {
        ...item,
        price: handlePriceString(item.price)
      }
    })

    if (editOffer) {
      const offerData = { orderItemPrices: formatItemPrices, extraItems: formatExtraItemPrices, deliveryDate, reference, tradeTerms, offerNumber: realOfferNumber };
      await offerApi.editOffer(editOffer.id, offerData, file)
        .then(response => {
          history.push('/orders')
          return
        }).catch(error => {
          enqueueSnackbar('Udfyld alle felter', { variant: 'error' })
          showSendOfferDialog(false)
          throw Error("failed")
        })
    } else {
      await offerApi.createOffer(order.id, formatItemPrices, formatExtraItemPrices, deliveryDate, file, total, reference, tradeTerms, realOfferNumber)
        .then(response => {
          history.push('/orders')
          return
        }).catch(error => {
          console.log(error)
          enqueueSnackbar('Udfyld alle felter', { variant: 'error' })
          showSendOfferDialog(false)
          throw Error("failed")
        })
    }
  }

  return (
    <Box>
      <Box display='flex'>
        <Box width={'66%'}>
          <Box className={classes.container}>
            <Messages requestId={order.id} />
          </Box>

          <Box className={classes.container} mt={2}>
            <FileTable
              orderId={props.orderId}
              orderItems={order.orderItems}
              offer
              orderItemPrices={orderItemPrices}
              handlePriceUpdate={handlePriceUpdate}
              error={error}
              updateDraft={updateDraft}
            />
          </Box>

          <Box className={classes.container} mt={2}>
            <ExtraCostTable
              extraItems={extraItems}
              setExtraItems={setExtraItems}
              updateDraft={updateDraft}
            />
          </Box>

          <Box display='flex' flexDirection={'column'} alignItems='flex-end' width='100%' mt={2}>

            <Box className={classes.container} width='fit-content' p={2} mb={2}>
              <Typography variant='h5'>
                Ordrestørrelse:
                <NumberFormat
                  value={order.orderItems.reduce((a, b) => Number(a) + Number(b.quantity), 0)}
                  displayType={'text'} thousandSeparator={'.'}
                  decimalSeparator={','}
                  suffix={' stk.'}
                  style={{ marginLeft: '5px' }}
                />
              </Typography>
            </Box>

            <Box className={classes.container} width='fit-content' p={2} ml={2} border='2px solid orange'>
              <Typography variant='h5'>
                {`Total pris ekskl. moms: `}
                <NumberFormat
                  value={total}
                  displayType={'text'}
                  thousandSeparator={'.'}
                  decimalSeparator={','}
                  decimalScale={2}
                  suffix={' kr.'}
                  style={{ marginLeft: '5px' }}
                />
              </Typography>
            </Box>
          </Box>
        </Box>

        <Box width={'34%'} ml={2}>
          <Accordion defaultExpanded={true} elevation={0} sx={accordionStyle}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} >
              <ShoppingCartIcon />
              <Typography pl={1} variant='h5'>Ordreinformation</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <CustomerInfo values={order.orderInfo} />
            </AccordionDetails>
          </Accordion>

          <Box className={classes.container} p={2} mt={2}>
            <OfferNumber edit={true} offerNumber={offerNumber} onChange={(e) => setOfferNumber(e.target.value)} />
          </Box>

          <Box className={classes.container}>
            <Box display='flex' p={2} mt={2}>
              <OfferFile file={file} setFile={setFile} editOffer={editOffer} />
              <DeliveryDate deliveryDate={deliveryDate} setDeliveryDate={setDeliveryDate} reference={reference} setReference={setReference} error={error} />
            </Box>
            <Box pl={2}>
              <FormControlLabel control={<Checkbox name="accept" onChange={(e) => setTradeTerms(e.target.checked)} checked={tradeTerms} />} label="Vedhæft" />
              <Link onClick={onViewTradeTerms} sx={{ cursor: 'pointer', textDecoration: 'underline', color: '#7B61FF' }}>
                handelsbetingelser
              </Link>
            </Box>
          </Box>
        </Box>
      </Box>

      <Box display='flex' justifyContent='space-between' mt={3}>
        <Button variant='contained' color='secondary' onClick={props.back}>
          Tilbage
        </Button>
        <Button variant='contained' onClick={() => validateOffer().finally(function () { setValidating(false) })}>
          Send tilbud
        </Button>
      </Box>

      <SendOfferDialog
        open={sendOfferDialog}
        close={() => showSendOfferDialog(false)}
        sendOffer={sendOffer}
        company={order.orderInfo.company}
        reference={reference}
        deliveryDate={deliveryDate}
        file={file}
        total={total}
        tradeTerms={tradeTerms}
      />
    </Box>
  )
}

const useStyles = makeStyles({
  container: {
    boxShadow: '0px 5px 15px rgba(0, 0, 0, 0.2)',
    borderRadius: '5px',
    backgroundColor: 'white'
  },
  commentField: {
    border: '1px solid #C7C7C7',
    borderRadius: '5px',
    minHeight: '100px'
  }
});

const accordionStyle = {
  boxShadow: '0px 5px 15px rgba(0, 0, 0, 0.2)',
  borderRadius: '5px'
}
