import React from 'react'

import makeStyles from '@mui/styles/makeStyles'
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@mui/material'
import NumberFormat from 'react-number-format'

const cells = [
  { id: 'name', label: 'Andet', align: 'left', sortable: true },
  { id: 'price', label: 'Pris', align: 'right', sortable: true },
]

export default function ExtraCostTable(props) {
  const classes = useStyles()

  return (
    <React.Fragment>
      <Table className={classes.fileTable} size='small'>

        <TableHead>
          <TableRow className={classes.tableHeader}>

            {cells.map(cell => (
              <TableCell key={cell.id} align={"center"} style={{ border: '2px solid white' }}>
                <Box color='white' display='flex' justifyContent='center' alignItems='center'>
                  <Typography variant='h6'>{cell.label}</Typography>
                </Box>
              </TableCell>
            ))}

          </TableRow>
        </TableHead>

        <TableBody>

          {props.extraItems.map((item, rowIndex) => (
            <TableRow key={rowIndex}>

              <TableCell width='80%'>
                <Typography textAlign='left'>
                  {item.name}
                </Typography>
              </TableCell>

              <TableCell width='20%'>
                <Typography variant='h6'>
                  <NumberFormat
                    value={Number(item.price)}
                    displayType={'text'}
                    thousandSeparator={'.'}
                    decimalSeparator={','}
                    decimalScale={2}
                    suffix={' kr.'}
                    style={{ marginLeft: '5px' }}
                  />
                </Typography>
              </TableCell>

            </TableRow>
          ))}

        </TableBody>

      </Table>
    </React.Fragment>
  )
}

const useStyles = makeStyles({
  fileTable: {
    width: '100%',
    borderCollapse: 'collapse',
    background: 'white'
  },
  tableHeader: {
    backgroundColor: '#061B37',
  },
  priceInput: {
    height: '40px',
    border: '2px solid #DD8500',
    textAlign: 'center',
    borderRadius: '5px',
    padding: 0
  },
  nameInput: {
    height: '40px',
    border: '2px solid #DD8500',
    borderRadius: '5px',
    padding: '0 10px'
  },
  amountContainer: {
    width: '90%',
    border: 'none !important',
    '&::before': {
      border: 'none !important'
    },
    '&::after': {
      border: 'none !important'
    }
  }
})