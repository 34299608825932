import MessageIcon from '@mui/icons-material/Message'
import {
  Box, Stack, Typography
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { daDK, DataGrid } from '@mui/x-data-grid'
import lodash from 'lodash'
import React, { useEffect, useState } from 'react'
import NumberFormat from 'react-number-format'
import { useHistory } from "react-router-dom"
import * as offerApi from '../../api/offer'
import { OrderStatus } from '../../helpers/enums'
import { formatDate } from '../../helpers/utilities'
import { SearchBar } from '../searchBar'

export default function OffersSent(props) {
  const classes = useStyles()
  const history = useHistory()

  const [loading, setLoading] = useState(true)
  const [offers, setOffers] = useState([])
  const [filteredOffers, setFilteredOffers] = useState([])

  const [search, setSearch] = useState('')
  const [orderBy, setOrderBy] = useState()
  const [direction, setDirection] = useState()

  const columns = [
    { flex: 1, field: 'id', headerName: 'Tilbuds ID.', renderCell: (x) => <Box fontWeight={'bold'}>{x.row.orderId}</Box> },
    { flex: 1, field: 'orderDate', headerName: 'Ordredato', renderCell: (x) => <Box fontWeight={'bold'}>{formatDate(new Date(x.row.orderDate), 'PP')} </Box> },
    { flex: 1, field: 'company', headerName: 'Kunde', renderCell: (x) => <Box fontWeight={'bold'}>{x.row.company}</Box> },
    { flex: 1, field: 'reference', headerName: 'Reference' },
    { flex: 1, field: 'orderSize', headerName: 'Ordrestørrelse', valueGetter: (x) => `${x.row.orderSize} stk.` },
    {
      flex: 1, field: 'total', headerName: 'Fremsendte pris', renderCell: (x) => <Box py={1}>
        <NumberFormat
          value={Number(x.row.total)}
          displayType={'text'}
          thousandSeparator={'.'}
          decimalSeparator={','}
          decimalScale={2}
          suffix={' kr.'}
        />
      </Box>
    },
    {
      flex: 1, field: 'order.order_request_comments', headerName: 'Beskeder', minWidth: 150, renderCell: (x) => <>
        {
          x.row.comments.length > 0 ?
            <Stack gap={1} direction="row">
              <Typography>Ny besked</Typography>
              {<MessageIcon />}
            </Stack>

            :
            <Typography>--</Typography>
        }
      </>
    },
    { flex: 1, headerName: 'Kategori', renderCell: (x) => <Box py={1}>{renderCategory(x.row)}</Box> }
  ]

  useEffect(() => {
    async function getOffers() {
      setLoading(true)
      const data = await offerApi.getOffers()

      if (data) {
        setOffers(data.rows)
      }
      setLoading(false)
    }
    setOrderBy("orderDate")
    setDirection("desc")
    getOffers()
  }, [])

  useEffect(() => {
    let clone = lodash.cloneDeep(offers)
    let _search = search.toLowerCase()

    if (_search) {
      clone = clone.filter(c =>
        c.orderId === Number(_search) ||
        c.company.toLowerCase().includes(_search) ||
        c.reference.toLowerCase().includes(_search)
      )
    }

    if (orderBy) {
      if (direction === 'asc') {
        clone = clone.sort((a, b) => lodash.get(a, orderBy) < lodash.get(b, orderBy) ? -1 : 1)
      } else {
        clone = clone.sort((a, b) => lodash.get(a, orderBy) > lodash.get(b, orderBy) ? -1 : 1)
      }
    }

    setFilteredOffers(clone)
  }, [offers, search, orderBy, direction])

  const renderCategory = (offer) => {
    if (offer.orderStatus === OrderStatus.BENCHMARKED) {
      return "Benchmark tilbud"
    }

    if (offer.orderStatus === OrderStatus.COMPLETED && !offer.orderWon) {
      return "Tabt tilbud"
    }

    if (offer.orderStatus === OrderStatus.COMPLETED && offer.orderWon) {
      return "Vundet tilbud"
    }

    if (offer.orderStatus === OrderStatus.CLOSED) {
      return "Tilbud afvist"
    }

    return "Åben"
  }

  const directToOrder = (offer) => {
    history.push(`/offer/${offer.orderId}`)
  }

  return (
    <React.Fragment>

      <SearchBar search={setSearch} />

      <Box sx={{ backgroundColor: 'white', width: '100%' }}>
        <DataGrid
          rows={filteredOffers}
          columns={columns}
          autoHeight={true}
          pageSize={25}
          rowsPerPageOptions={[10, 25, 50, 100]}
          disableColumnMenu={true}
          disableSelectionOnClick={true}
          onRowClick={(x) => directToOrder(x.row)}
          loading={loading}
          localeText={daDK.components.MuiDataGrid.defaultProps.localeText}
        />
      </Box>

      {!loading && filteredOffers.length === 0 &&
        <Box className={classes.noRequests}>
          <Typography variant='h4'>
            Du har ingen sendte tilbud.
          </Typography>
        </Box>}
    </React.Fragment>
  )
}

const useStyles = makeStyles({
  orderTable: {
    width: '100%',
    minWidth: '1050px',
    borderCollapse: 'collapse',
    background: 'white'
  },
  tableHeader: {
    backgroundColor: '#061B37',
  },
  tableRow: {
    cursor: 'pointer'
  },
  noRequests: {
    width: '100%',
    height: '400px',
    background: 'white',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column'
  }
})